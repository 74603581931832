import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import Avatar from '../../paginas/img/avatar.jpg'

const VerUsuarios = ({data, iglesias, Seleccionar, Roll}) => {

	let Padre = ''
	if (Roll == 'superadmin'){
		Padre = 'Padre'
	}

	const BuscaIglesia = (ids, denomina) =>{
        
		let Ids = JSON.parse(ids)
        if(Ids.length > 1){
            return denomina
        }else{
            let busquedaId = Ids[0]
            let result = ''
            iglesias.map(item =>{
                if( busquedaId == item.id)
                result = item.nombre
            })
            return result
        }
    }

	return(
		<div>
			<MDBTable hover>
			  <HeaderTabla array={['Img','Activo','Usuario','Nombre','Iglesia','Roll',Padre]} />
		      <MDBTableBody>

		      	{data.map((item, index) => {
		      		return(
				        <tr key={index}>
				          <td><div className="img_user"><img src={Avatar}/></div></td>
				          <td>{item.activo === 'true' ? 'Si' : 'No'}</td>
				          <td>{item.usuario}</td>				          
				          <td>{item.nombre}</td>
				          <td>{BuscaIglesia(item.iglesiasids, item.denomina)}</td>
				          <td>{item.roll}</td>
						  {Roll == 'superadmin' ? 
						  	<td>{item.usuariopadre}</td>
						  :null}
				          <td className="tabla_funciones">
						  	<BotonesFormulario SeleccionarCategoria={Seleccionar} item={item} Roll={Roll}/>
				          </td>
				        </tr>
			        )

		        })}

		      </MDBTableBody>
		    </MDBTable>
		</div>
	)
}

export default VerUsuarios