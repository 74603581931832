import React from 'react'
import moment from 'moment'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format'

const VerCategorias = ({ data, seleccionaRegistro, baseUrl }) => {

	return (
		<MDBTable hover responsive>
			<HeaderTabla array={['Id', 'Nombre', 'Color', '']} />
			<MDBTableBody>

				{data.map((item, index) => {
					return (
						<tr key={index} className="tabla_productos">
							<td>{item.id}</td>
							<td>{item.nombre}</td>
							<td><span className='estados' style={{backgroundColor: item.color}}>{item.color}</span></td>
							<td className="tabla_funciones">
								<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />
							</td>
						</tr>
					)
				})}

			</MDBTableBody>
		</MDBTable>
	)
}

export default VerCategorias