import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import AutoCompleta from '../componentes/AutoCompleta'
import { NumericFormat } from 'react-number-format'


const SubirEditDonaciones = ({ baseUrl, data, setData, setFuncion, urlIngresos, registroSelect, TituloBtn, funcion, miembros, tiposDeOfrenda, mediosDePago, baseUrlMiembros}) => {

	const [registro, setRegistro] = useState(funcion === 'editar' ? {...registroSelect, tipoofrendaesp: registroSelect.tipoofrenda, fecha: moment(registroSelect.fecha).format("YYYY-MM-DD"),} : { fecha: moment().format("YYYY-MM-DD"), diezmo: 0 , ofrenda: 0, mediodepago: 'Efectivo', cedula: '', telefono: ''})
	const [error, setError] = useState({ activo: false })
	const [crearMiembro, setCrearMiembro] = useState(false)
	const [nombre, setNombre] = useState(funcion === 'editar' ? registroSelect.nombre : '')	

	//Consulta para subir o crear los datos 

	console.log('registro', registro)

	const creaFormData = (registro, METHOD) => {

		let f = new FormData()
		f.append('id', registro.id)
		f.append('fecha', moment(registro.fecha).format('YYYY-MM-DD'))
		f.append('total', parseInt(registro.diezmo) + parseInt(registro.ofrenda))
		f.append('tipoofrendaesp', registro.tipoofrendaesp == undefined ? '' : traeIdNombre(tiposDeOfrenda, registro.tipoofrendaesp))
		f.append('idmediodepago', traeIdNombre(mediosDePago, registro.mediodepago))
		f.append('diezmo', parseInt(registro.diezmo))
		f.append('ofrenda', parseInt(registro.ofrenda))
		f.append('idmiembro',parseInt(registro.idmiembro))
		//f.append('METHOD', 'POST')
		f.append('METHOD', METHOD)

		return f
	}

	const SubirDonacion = async (IdMiembro) => {

		let f = new FormData()
		f.append('fecha', moment(registro.fecha).format('YYYY-MM-DD'))
		f.append('total', parseInt(registro.diezmo) + parseInt(registro.ofrenda))
		f.append('tipoofrendaesp', registro.tipoofrendaesp == undefined ? '' : traeIdNombre(tiposDeOfrenda, registro.tipoofrendaesp))
		f.append('idmediodepago', traeIdNombre(mediosDePago, registro.mediodepago))
		f.append('diezmo', parseInt(registro.diezmo))
		f.append('ofrenda', parseInt(registro.ofrenda))
		f.append('idmiembro', parseInt(IdMiembro))
		f.append('METHOD', 'POST')

		await axios.post(baseUrl, f)

		.then(response => {
			console.log('result 2', response.data)
			let resultFormat = { ...response.data }
			setData([{
				...resultFormat, 
				nombre: nombre !== '' ? nombre : registro.nombre, 
				cedula: registro.cedula, 
				mediodepago: registro.mediodepago,
				tipoofrenda: undefined ? '' : registro.tipoofrendaesp
			}, ...data])
			setRegistro({ activo: false })
			errores('Limpio', setError)
			setFuncion('ver')


		}).catch(error => {
			console.log(error);
		})	
	}

	const peticionPost = async () => {

		let { id, cedula, telefono, diezmo, ofrenda, ofrendaesp, id_miembro, mediodepago} = registro

		console.log('registro', registro.nombre)

		if (!crearMiembro) {
			if (registro.nombre !== '' && diezmo !== undefined && ofrenda !== undefined && id_miembro !== undefined) {

				SubirDonacion(id_miembro)

			} else errores('CampoVacio', setError)
		} else {
				console.log('crea usuario entra', nombre, cedula, telefono)
				if (nombre !== undefined && diezmo !== undefined && ofrenda !== undefined && mediodepago !== undefined ) {

					let f = new FormData()
					f.append('nombre', nombre)
					f.append('cedula', cedula)
					f.append('telefono', telefono)
					f.append('METHOD', 'POST')

					await axios.post(baseUrlMiembros, f)
					.then(response => {
						console.log('result', response.data)
						let IdMiembro = response.data.id
						SubirDonacion(IdMiembro)
					}).catch(error => {
						console.log(error);
					})	
				}else{console.log('no entra')}
		}
	}

	const ProcedimientoPut = async () => {

		let { id, cedula, telefono, diezmo, ofrenda, ofrendaesp, id_miembro, mediodepago} = registro

		let f = creaFormData(registro, 'PUT')
		await axios.post(baseUrl, f)

			.then(response => {

				console.log('editado 2', response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre !== '' ? nombre : registro.nombre
						item.cedula = registro.cedula
						item.fecha = registro.fecha
						item.mediodepago = registro.mediodepago
						item.tipoofrenda = undefined ? '' : registro.tipoofrendaesp
						item.diezmo = registro.diezmo	
						item.ofrenda = registro.ofrenda
						item.telefono = registro.telefono
						item.total = parseInt(registro.diezmo) + parseInt(registro.ofrenda)
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)

				setRegistro({ activo: false })
				errores('Limpio', setError)
				setFuncion('ver')
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const mayoraCero = () => {
		if (registro.diezmo > 0 || registro.ofrenda > 0) {return true}
		else{return false}
	}

	const peticionPut = () => {
		if (registro.nombre !== '' && registro.mediodepago !== '' && mayoraCero()) {
			ProcedimientoPut()
			console.log('paso')
		} else errores('CampoVacio', setError)
	}


	const handleValueChange = (values, name) => {
		const { value } = values;
		// Usa el nombre para actualizar el campo correspondiente en el estado
		setRegistro((prevState) => ({
		  ...prevState,
		  [name]: value // Guarda el valor sin formato
		}));
	  };
	


	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-12'>

								<div className='row'>
									<div className='col-md-8'>
										<div className="form-group">
											<AutoCompleta 
												registro={registro} 
												setRegistro={setRegistro} 
												productos={miembros}
												buscador={nombre}
												setBuscador={setNombre}
											/>
										</div>
									</div>
									<div className='col-md-2'>
										<span>Fecha</span><br></br>
										<input
										className='form-control'
											style={{marginTop:10}}
											type="date"
											value={registro.fecha }
											onChange={e => setRegistro({ ...registro, fecha: e.target.value })}
										/>
									</div>
									<div className='col-md-2'>
										<span>Crear Miembro</span><br></br>
										<input
											type="checkbox"
											style={{height: 35, width: 35, marginTop:10}}
											checked={crearMiembro} // Asigna el estado al atributo "checked"
											onChange={() => setCrearMiembro(!crearMiembro)} // Maneja el cambio del checkbox
											/>
									</div>
									
								</div>

								<div className="row sinMargen">
									<div className='col-md-6'>
										<MDBInput
											label="Cedula" name="cedula" required className={error.cedula ? "form-control is-invalid" : null} outline
											value={registro.cedula}
											onChange={e => setRegistro({ ...registro, cedula: e.target.value })}
										/>
									</div>
									<div className='col-md-6'>
										<MDBInput label="Teléfono " name="telefono" required className={error.telefono ? "form-control is-invalid" : null} outline
											value={registro.telefono}
											onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
										/>
									</div>



									<div className='col-md-4'>
									<NumericFormat
										label="Diezmo"
										name="diezmo"
										thousandSeparator={true}
										prefix={'$'} // Si deseas añadir un prefijo (opcional)
										value={registro.diezmo}
										onValueChange={(values) => handleValueChange(values, 'diezmo')}
										customInput={MDBInput} // Usa MDBInput como componente de entrada
										className={error.diezmo ? "form-control is-invalid" : null}
										required
										outline
									/>
									</div>
									<div className='col-md-4'>
								
										<NumericFormat
											label="Ofrenda"
											name="ofrenda"
											thousandSeparator={true}
											prefix={'$'} // Si deseas añadir un prefijo (opcional)
											value={registro.ofrenda}
											onValueChange={(values) => handleValueChange(values, 'ofrenda')}
											customInput={MDBInput} // Usa MDBInput como componente de entrada
											className={error.ofrenda ? "form-control is-invalid" : null}
											required
											outline
										/>
									</div>

									<div className='col-md-4'>
										<select 
											className="browser-default custom-select" 
											name="tipoofrendaesp"
											value={registro.tipoofrendaesp}
											onChange={e => setRegistro({...registro, tipoofrendaesp: e.target.value})}
											>
												<option value="">Selecciona Tipo de Ofrenda Especial</option>
												{tiposDeOfrenda.map(item =>{
													return <option key={item.nombre} value={item.nombre}>{item.nombre}</option>
												})}

                                		</select>
									</div>

									<div className='col-md-6'>
										<div className="form-group">
        								<label>Selecciona un Medio de Pago</label>
											<select 
												className="browser-default custom-select" 
												name="Medio de Pago"
												value={registro.mediodepago}
												onChange={e => setRegistro({...registro, mediodepago: e.target.value})}
												>
													{mediosDePago.map(item =>{
														return <option key={item.nombre} value={item.nombre}>{item.nombre}</option>
													})}
											</select>
										</div>
									</div>

									<div className='col-md-6'>
										<span><b>Total: </b></span><br></br>
										
										<span style={{fontSize:33, fontWeight:400}}>
											<NumericFormat displayType="text" 
												value={parseInt(registro.diezmo) + parseInt(registro.ofrenda)}
												prefix={'$'} thousandSeparator={true}>												
											</NumericFormat>
										</span>

										
									</div>
								</div>

							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditDonaciones