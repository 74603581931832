import React, {useState} from 'react'

const HeaderUserPrin = ({Logo, Avatar, nombre, Roll, Iglesiasids, denomina, iglesias}) => {

    const [iglesiasSelct, setIglesiasSelct] = useState({})

    const BuscaIglesia = (ids) =>{
        if(ids.length > 1){
            return denomina
        }else{
            let busquedaId = ids[0]
            let result = ''
            iglesias.map(item =>{
                if( busquedaId == item.id)
                result = item.nombre
            })
            return result
        }
    }

    return(
        <div>
            <div className="contenedor linea">
                <img src={Logo} className="logo" />
            </div>

            <div className="col-md-12 row">
                <div className="col-xs-12 col-sm-1 col-md-3 ">
                    <img src={Avatar} className="avatar"/>
                </div>
                <div className="col-xs-12 col-sm-11 col-md-9 cont_datos_usuario">
                    <h5>{nombre}</h5>
                    <p>{Roll} (Cod:{Iglesiasids})</p>
                    <p>{BuscaIglesia(Iglesiasids)}</p>
                    <div>
                        <select 
                            className="browser-default selectIglesia" 
                            name="Selecciona Iglesia"
                            value={iglesiasSelct.nombre}
                            onChange={e => setIglesiasSelct(e.target.value)}
                            >
                            <option value="">Selecciona Iglesia</option>
                            {iglesias.map((item, index) => {
                                return(
                                    <option 
                                        key={index}
                                        value={item}>
                                        {item.nombre}
                                    </option>
                                )}
                            )}
                            <option value={'Todas'}>Todas</option>
                        </select><br></br>
					</div>
                </div>
            </div>
        </div>
    )
}

export default HeaderUserPrin