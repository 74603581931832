import React, {useState, useEffect} from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import AsignarIglesiasSelect from './AsignarIglesiasSelect'
import IglesiasSelect from './IglesiasSelect'

const SubirUsuario = ({urlUsuarios, usuarios, setUsuarios, Titulo, iglesias, Roll, setFuncion, Iglesiasids, UsuarioPadre, UsuarioActivo}) => {

	const [iglesiaSelect, setIglesiaSelect] = useState('')
	const [iglesiasSubidas, setIglesiasSubidas] = useState([])
	const [registro, setRegistro] = useState({activo:true, iglesiasids: 0})
	//errores
	const [error, setError] = useState({activo: false, mensaje: ''})

	const errores = codigo => {
		switch (codigo){
			case 'UserExiste':
				setError({activo: true, mensaje: 'el usuario ya existe', campoUsuario: true})
				break 
			case 'CampoVacio':
				setError({activo: true, mensaje: 'Hay campos vacios'})
				break 
			case 'Limpio':
				setError({activo: false, mensaje: ''})
				break 
				
		}
	}


	//Comprueba si existe un usuario antes de guardarlo
	const existeUsuario = user => {
		let resultado = false
		usuarios.map((item) => {
			let {usuario} = item
			if(usuario === user)
				resultado = true
			})
		return resultado
	}

	const DepuraIds = array =>{

		let Arr2 = []
		array.map(item => {
			Arr2.push(item.id)
		})
		const result = Arr2.filter((valor, indice) => {
			return Arr2.indexOf(valor) === indice;
		  });
		return result
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () =>{
		const {activo, usuario, clave, nombre, telefono, roll, denomina} = registro
		const idsIglesias = DepuraIds(iglesiasSubidas)

		let UsuarioPadreFull = ''

		if(Roll == 'superadmin'){
			console.log('padre global:' ,UsuarioPadre)
			console.log('padre nuevo:' ,registro.usuariopadre)
			if(UsuarioPadre !== ''){
				UsuarioPadreFull = UsuarioActivo
			}else if (registro.usuariopadre !== '') {
				UsuarioPadreFull = registro.usuariopadre
			}else if (registro.usuariopadre !== 'Principal') {
				UsuarioPadreFull = ''
			}
		}else if (Roll == 'Admin'){

			if(UsuarioPadre !== ''){
				//Si no tiene padre como el usuario principal, asigna en padre el usuario
				UsuarioPadreFull = UsuarioPadre
				console.log('usuario: ',UsuarioActivo)
				console.log('padre:', UsuarioPadre)
			}
			if(UsuarioPadre == '' || UsuarioPadre == undefined){
				UsuarioPadreFull = UsuarioActivo
				// console.log('usuario: ',UsuarioActivo)
				// console.log('padre: ',UsuarioPadre)
			}
		}

		if(!existeUsuario(usuario)){
			if(usuario !== '' && clave !== '' && nombre !== '' && telefono !== '' && roll !== '' && denomina !== ''  && idsIglesias.length > 0 ){
				let f = new FormData()
				f.append('activo', activo.toString())
				f.append('usuario', usuario)
				f.append('clave', clave)
				f.append('nombre', nombre)
				f.append('telefono', telefono)
				f.append('roll', roll)
				f.append('iglesiasids', JSON.stringify(idsIglesias))
				f.append('denomina', denomina)
				f.append('usuariopadre', UsuarioPadreFull)
				f.append('METHOD', 'POST')

				await axios.post(urlUsuarios, f)

				.then(response=>{
				  setUsuarios(usuarios.concat(response.data));
			      setRegistro({activo:true, iglesiasids: 0})
				  setIglesiaSelect('')
				  setIglesiasSubidas([])
				  setFuncion('ver')
			      errores('Limpio')
				  console.log('paso')
			    }).catch(error=>{
			      console.log(error);
			    })
			}else errores('CampoVacio')
		 }else errores('UserExiste')
		
	}

	const eliminaCombinacion = index => e => {
		e.preventDefault()
		setIglesiasSubidas(iglesiaSel => iglesiaSel.filter((_, cont) => cont !== index));
	}

	const buscaIglesia = nombre =>{

		let objet = {}
		iglesias.map(item =>{
			if(nombre == item.nombre){objet = item}
		})
		return objet
	}

	const subeIglesia = e => {

		if (iglesiaSelect !== ""){
			setIglesiasSubidas([
				...iglesiasSubidas,
				buscaIglesia(iglesiaSelect)
			])
		}else console.log('campos vacios')
	}


	return(
		<div>
			<div>
				<br/>
				<h2>Subir {Titulo}:</h2>	
			</div>
			<div className="row">
				<div className="col-md-12">
						{error.activo ? 
							<span className="error"> Error: {error.mensaje}</span> : null
							}
						<div><hr/>
						<div className='custom-control custom-switch '>
					        <input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
					          checked={registro.activo}
							  onChange={e => setRegistro({...registro, activo: !registro.activo})}
					          readOnly
					        />
					        <label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
			      		</div>

						  <MDBInput 
							label="Nombre Completo" name="nombre" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
							value={registro.nombre}
							onChange={e => setRegistro({...registro, nombre: e.target.value})}
						/>

						<div className="row sinMargen">
							<div className="col-md-6 col-sm-12">
								<MDBInput name="usuario" required className={error.campoUsuario ? "form-control is-invalid" : null} outline 
									label="Correo/Usuario"
									type='email'
									value={registro.usuario}
									onChange={e => setRegistro({...registro, usuario: e.target.value})}						
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<MDBInput label="Clave" type="password" name="clave" required className={error.campoClave ? "form-control is-invalid" : null} outline 
									value={registro.clave}
									onChange={e => setRegistro({...registro, clave: e.target.value})}	
								/>
							</div>
						</div>


						<div className="row sinMargen">
							<div className="col-md-6 col-sm-12">
								<MDBInput 
									label="Telefono" name="Telefono" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
									value={registro.telefono}
									onChange={e => setRegistro({...registro, telefono: e.target.value})}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<MDBInput 
									label="Denominación" name="Telefono" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
									value={registro.denomina}
									onChange={e => setRegistro({...registro, denomina: e.target.value})}
								/>
							</div>
						</div>

						<div>
						        <select 
						        	className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll" 
						        		value={registro.roll} 
										onChange={e => setRegistro({...registro, roll: e.target.value})}>
									          <option value="">Selecciona Roll</option>
											  <option value="Admin">Admin</option>
									          <option value="Contador">Contador</option>
											  <option value="Secretario">Secretario</option>
						        </select>
								<br/> <br/> 
						</div>

						{Roll == 'superadmin' ? 
							<div>
								<select 
									className="browser-default custom-select" 
									name="usuariopadre"
									value={registro.usuariopadre}
									onChange={e => setRegistro({...registro, usuariopadre: e.target.value})}
									>
									<option value="">Selecciona Usuario Padre</option>
									{usuarios.map((item, index) => {
										return(
											<option 
												key={index}
												value={item.usuario}>
												{item.nombre}
											</option>
										)}
									)}
									<option value={'Principal'}>Principal</option>
								</select><br></br><br></br>
							</div>
						: null }

						<h5>Asignar Iglesias:</h5>
						<div className="row">
							<div className="col-md-6">
								<AsignarIglesiasSelect 
									iglesiaSelect={iglesiaSelect}
									setIglesiaSelect={setIglesiaSelect}
									iglesias={iglesias}
									subeIglesia={subeIglesia}
									Roll={Roll}
									Iglesiasids={Iglesiasids}
									tipo={'crear'}
								/>
							</div>
							<div className="col-md-6">
								<IglesiasSelect 
									iglesiasSubidas={iglesiasSubidas}
									eliminaCombinacion={eliminaCombinacion}
									Roll={Roll}/>
							</div>
						</div>
					

						<div>
					    </div>
					    <br/>
						<div className="col text-center">	
							<button 
								onClick={peticionPost}
								className="btn boton_estandar"							
								>Subir Usuario
							</button>	
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirUsuario