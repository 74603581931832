import React, {useState, useEffect} from 'react'
import VerMembresia from './VerMembresia'
import SubirEditMembresia from './SubirEditMembresia'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'
import { MDBInput } from 'mdbreact';
import { NumericFormat } from 'react-number-format'

const CalculadoraAdmin = ({baseUrl, titulo, Iglesiasids, Roll}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [billetes, setRegistroSelect] = useState({
		dosmil: 0,
		cincomil: 0,
		diezmil: 0,
		veintemil: 0,
		cincuenta: 0,
		cien: 0,
	})

	const [cuentas, setCuentas] = useState({
		cuenta1: 0,
		cuenta2: 0,
		cuenta3: 0
	})

	const SumarBilletes = () => {
		let {dosmil, cincomil, diezmil, veintemil, cincuenta, cien} = billetes
		let suma = (parseInt(dosmil)*2000) + (parseInt(cincomil)*5000)  + (parseInt(diezmil)*10000) + (parseInt(veintemil)*20000) + (parseInt(cincuenta)*50000) + (parseInt(cien)*100000)
		return suma
	}

	const SumarCuentas = () => {
		let {cuenta1, cuenta2, cuenta3} = cuentas
		let suma = (parseInt(cuenta1)) - ((parseInt(cuenta2))  + (parseInt(cuenta3)) )
		return suma
	}

	


	return(
		<div>
			<Header 
				titulo={'Calculadora'} 
			/>
			<div style={{display: 'flex', padding: 20}}>
				<div style={{flex: 1, backgroundColor: '#d8e0f3', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$2.000</b></span>
					<MDBInput
						label="$2.000" name="dosmil" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.dosmil}
						onChange={e => setRegistroSelect({ ...billetes, dosmil: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#ffdc5a', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$5.000</b></span>
					<MDBInput
						label="$5.000" name="cincomil" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.cincomil}
						onChange={e => setRegistroSelect({ ...billetes, cincomil: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#ff4465', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$10.000</b></span>
					<MDBInput
						label="$10.000" name="diezmil" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.diezmil}
						onChange={e => setRegistroSelect({ ...billetes, diezmil: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#ffcaa9', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$20.000</b></span>
					<MDBInput
						label="$20.000" name="veintemil" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.veintemil}
						onChange={e => setRegistroSelect({ ...billetes, veintemil: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#d9a0d7', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$50.000</b></span>
					<MDBInput
						label="$50.000" name="cincuenta" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.cincuenta}
						onChange={e => setRegistroSelect({ ...billetes, cincuenta: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#99d68e', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>$100.000</b></span>
					<MDBInput
						label="$100.000" name="cien" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={billetes.cien}
						onChange={e => setRegistroSelect({ ...billetes, cien: e.target.value })}
					/>
				</div>
			</div>
			<span style={{fontSize: 20, fontWeight: 450}}>Total: <NumericFormat displayType="text" value={SumarBilletes()} prefix={'$'} thousandSeparator={true} /></span>

			<br /><br />
			<span style={{fontSize: 20}}>Cuentas:</span>
			<div style={{display: 'flex', padding: 20}}>
				<div style={{flex: 1, backgroundColor: '#d8e0f3', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>Saldo</b></span>
					<MDBInput
						label="Saldo" name="Saldo" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={cuentas.cuenta1}
						onChange={e => setCuentas({ ...cuentas, cuenta1: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#ffdc5a', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>Efectivo</b></span>
					<MDBInput
						label="Efectivo" name="Efectivo" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={cuentas.cuenta2}
						onChange={e => setCuentas({ ...cuentas, cuenta2: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#ff4465', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize:18}}><b>Cuenta</b></span>
					<MDBInput
						label="Cuenta" name="Cuenta" required type='number' outline style={{margin: 0, backgroundColor: 'white'}}
						value={cuentas.cuenta3}
						onChange={e => setCuentas({ ...cuentas, cuenta3: e.target.value })}
					/>
				</div>
				<div style={{flex: 1, backgroundColor: '#99d68e', padding: 10, marginRight: 5, borderRadius: 10}}>
					<span style={{fontSize: 20, fontWeight: 450}}>Total: </span><br></br>
					<span style={{fontSize: 20, fontWeight: 450}}><NumericFormat displayType="text" value={SumarCuentas()} prefix={'$'} thousandSeparator={true} /></span>
				</div>
			</div>
			

		</div>
	)
}

export default CalculadoraAdmin