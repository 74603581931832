import React, {useState, useEffect} from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import AsignarIglesiasSelect from './AsignarIglesiasSelect'
import IglesiasSelect from './IglesiasSelect'

const EditarUsuario = ({Titulo, iglesias, usuarios, Roll, setFuncion, peticionPut, usuarioSelect, setUsuarioSelect, iglesiasSubidas, setIglesiasSubidas, Iglesiasids}) => {

	const [iglesiaSelect, setIglesiaSelect] = useState('')
	const [error, setError] = useState({activo: false, mensaje: ''})
	//almacena datos del formulario


	const eliminaCombinacion = index => e => {
		e.preventDefault()
		setIglesiasSubidas(iglesiaSel => iglesiaSel.filter((_, cont) => cont !== index));
	}

	const buscaIglesia = nombre =>{
		let objet = {}
		iglesias.map(item =>{
			if(nombre == item.nombre){objet = item}
		})
		return objet	
	}

	const subeIglesia = e => {
		if (iglesiaSelect !== ""){
			setIglesiasSubidas([
				...iglesiasSubidas,
				buscaIglesia(iglesiaSelect)
			])
		}else console.log('campos vacios')
	}

	const remplazaIdsaIglesias = (ids) => {
		//console.log(ids)
		let arrayIds = JSON.parse(ids)
		let arrayResult = []
		iglesias.map(item => {
			arrayIds.map(itemids => {
				if(itemids == item.id)
				arrayResult.push(item)
			})
		})
		//console.log(arrayResult)
		setIglesiasSubidas(arrayResult)
	}

	useEffect(() => {
		remplazaIdsaIglesias(usuarioSelect.iglesiasids)
	},[usuarioSelect])	


	return(
		<div>
			<div>
				<br/>
				<h2>Editar Usuario:</h2>	
			</div>
			<div className="row">
				<div className="col-md-12">
						{error.activo ? 
							<span className="error"> Error: {error.mensaje}</span> : null
							}
						<div><hr/>
						<div className='custom-control custom-switch '>
					        <input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
					          checked={usuarioSelect.activo}
							  onChange={e => setUsuarioSelect({...usuarioSelect, activo: !usuarioSelect.activo})}
					          readOnly
					        />
					        <label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
			      		</div>

						  <MDBInput 
							label="Nombre Completo" name="nombre" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
							value={usuarioSelect.nombre}
							onChange={e => setUsuarioSelect({...usuarioSelect, nombre: e.target.value})}
						/>

						<div className="row sinMargen">
							<div className="col-md-6 col-sm-12">
								<MDBInput name="usuario" required className={error.campoUsuario ? "form-control is-invalid" : null} outline 
									label="Correo/Usuario"
									type='email'
									value={usuarioSelect.usuario}
									onChange={e => setUsuarioSelect({...usuarioSelect, usuario: e.target.value})}						
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<MDBInput label="Clave" type="password" name="clave" required className={error.campoClave ? "form-control is-invalid" : null} outline 
									value={usuarioSelect.claveNueva}
									onChange={e => setUsuarioSelect({...usuarioSelect, claveNueva: e.target.value})}	
								/>
							</div>
						</div>


						<div className="row sinMargen">
							<div className="col-md-6 col-sm-12">
								<MDBInput 
									label="Telefono" name="Telefono" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
									value={usuarioSelect.telefono}
									onChange={e => setUsuarioSelect({...usuarioSelect, telefono: e.target.value})}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<MDBInput 
									label="Denominación" name="Telefono" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline 
									value={usuarioSelect.denomina}
									onChange={e => setUsuarioSelect({...usuarioSelect, denomina: e.target.value})}
								/>
							</div>
						</div>

						{Roll == 'superadmin' || Roll == 'Admin' ? 

						<div>
							<select 
								className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll" 
									value={usuarioSelect.roll} 
									onChange={e => setUsuarioSelect({...usuarioSelect, roll: e.target.value})}>
										<option value="">Selecciona Roll</option>
										<option value="Admin">Admin</option>
										<option value="Contador">Contador</option>
										<option value="Secretario">Secretario</option>
							</select>
							<br/> <br/> 
						</div>
					
						:null}



						{Roll == 'superadmin' ? 
							<div>
								<select 
									className="browser-default custom-select" 
									name="usuariopadre"
									value={usuarioSelect.usuariopadre}
									onChange={e => setUsuarioSelect({...usuarioSelect, usuariopadre: e.target.value})}
									>
									<option value="">Selecciona Usuario Padre</option>
									{usuarios.map((item, index) => {
										return(
											<option 
												key={index}
												value={item.usuario}>
												{item.nombre}
											</option>
										)}
									)}
									<option value={'Principal'}>Principal</option>
								</select><br></br><br></br>
							</div>
						: null }

						<h5>Asignar Iglesias:</h5>
						<div className="row">
							<div className="col-md-6">
								<AsignarIglesiasSelect 
									iglesiaSelect={iglesiaSelect}
									setIglesiaSelect={setIglesiaSelect}
									iglesias={iglesias}
									subeIglesia={subeIglesia}
									Roll={Roll}
									Iglesiasids={Iglesiasids}
									tipo={'editar'}
								/>
							</div>
							<div className="col-md-6">
								<IglesiasSelect 
									iglesiasSubidas={iglesiasSubidas}
									eliminaCombinacion={eliminaCombinacion}
									usuariopadre={usuarioSelect.usuariopadre}
									Roll={Roll}
								/>
							</div>
						</div>
					

						<div>
					    </div>
					    <br/>
						<div className="col text-center">
								<button 
									onClick={peticionPut}
									className="btn boton_estandar"							
									>Actualizar {Titulo}
								</button>
								<button 
									onClick={e=>setFuncion('ver')}
									className="btn boton_estandar"							
									>Cancelar
								</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditarUsuario