import React, {useState, useEffect} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const EditarIglesias = ({baseUrl, setFuncion, iglesias, setIglesias, Titulo, registroSelect, setRegistroSelect}) => {


	const [error, setError] = useState({
		activo: false,
		mensaje: 'Hola'
	})

	const errores = codigo => {
		switch (codigo){
			case 'CampoVacio':
				alert('Hay campos vacios')
				//setError({activo: true, mensaje: 'Hay campos vacios'})
				break 
			case 'Precio':
				alert('El Campo Precio debe ser diferente al de reabaja')
				//setError({activo: true, mensaje: 'El Campo Precio debe ser diferente al de reabaja'})
				break 
			case 'Imagen':
				alert('El archivo pesa más de 2mb o tiene un formato no admitido')
				//setError({activo: true, mensaje: 'El archivo pesa más de 2mb o tiene un formato no admitido'})
				break
			case 'Limpio':
				setError({activo: false, mensaje: ''})
				break 
				
		}
	}

	const peticionPut = async () =>{

        let {id, activo, fecha, nombre, nit, tipo, ciudad, direccion, denomina, pastor, telefono, imagen, imagenNueva, nombreImaNueva} = registroSelect


		if (imagenNueva !== undefined){
			let formatosPermitidos = ['.png','.jpeg','.jpg','.JPEG']
			let formato = typeof(imagenNueva) !== "string" ? imagenNueva.type.replace('image/','.') : null;
	
			if(imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)){
				console.log('nueva imagen')
				let f = new FormData()
				//let NombreIma = RutaServ+fecha+NombreImagen+formato
				//let NombreIma = RutaServ+imagen
				f.append('id', id)
				f.append('activo', activo)
				f.append('fecha', fecha)
				f.append('nombre', nombre)
				f.append('nit', nit)
				f.append('tipo', tipo)
				f.append('ciudad', ciudad)
				f.append('direccion', direccion)
				f.append('denomina', denomina)
				f.append('pastor', pastor)
				f.append('telefono', telefono)
				f.append('imagen', imagenNueva)
				f.append('nombre_imagen', nombreImaNueva)
				f.append('METHOD', 'PUT')

				await axios.post(baseUrl, f)

				.then(response=>{

					let dataNueva = iglesias
					      dataNueva.map(item=>{
					        if(item.id === id){
					          item.activo = activo
					          item.fecha = fecha
					          item.nombre = nombre
					          item.nit = nit
							  item.tipo = tipo
							  item.ciudad = ciudad
							  item.direccion = direccion
							  item.denomina = denomina
							  item.pastor = pastor
							  item.telefono = telefono
					          item.imagePrev = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
							  item.imagen = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
					        }
					    })
						setIglesias(dataNueva)

					//console.log('result', response.data)
					// let resultFormat = {
					// 	...response.data, 
					// 	imagePrev: nombreImaNueva === '' ? imagenNueva : nombreImaNueva,
					// 	imagen: nombreImaNueva === '' ? imagenNueva : nombreImaNueva }
					// setIglesias([resultFormat, ...iglesias])

					console.log('enviado')
					errores('Limpio')
				}).catch(error=>{
					console.log('muestra erro: '+error);
				})
				setFuncion('ver')
			}
					// console.log('nombreImagenFormat: '+fecha+NombreImagen+formato)
		}else if(nombre !== '' && pastor !== '' && imagen !== ''){
				let f = new FormData()
				//let NombreIma = RutaServ+fecha+NombreImagen+formato
				//let NombreIma = RutaServ+imagen
				f.append('id', id)
				f.append('activo', 'true')
				f.append('fecha', fecha)
				f.append('nombre', nombre)
				f.append('nit', nit)
				f.append('tipo', tipo)
				f.append('ciudad', ciudad)
				f.append('direccion', direccion)
				f.append('denomina', denomina)
				f.append('pastor', pastor)
				f.append('telefono', telefono)
				f.append('imagen', imagen)
				//f.append('nombre_imagen', NombreIma)
				f.append('METHOD', 'PUT')

				await axios.post(baseUrl, f)

				.then(response=>{

					console.log('result', response.data)
					let resultFormat = {...response.data, imagePrev: imagen, imagen: imagen}
					//let resultFormat = {...response.data, imagePrev: NombreIma, imagen: NombreIma}
					setIglesias([resultFormat, ...iglesias])

					console.log('enviado')
					errores('Limpio')
				}).catch(error=>{
					console.log('muestra erro: '+error);
				})
				setFuncion('ver')
		}else errores('CampoVacio')
		
	}

	const recibeImagen = e => {

		let imagen = e.target.files[0]

		const fecha = moment(new Date()).format("YYYYMMDD-HHmmss");

		let formato = imagen.type.replace('image/','.');
		let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
		let RutaServ = 'images/'
		console.log('nombreImagenFormat: '+fecha+NombreImagen+formato)

		//let formatosPermitidos = ['.png','.jpeg','.jpg']

		setRegistroSelect({
			...registroSelect, 
			imagePrev: URL.createObjectURL(imagen),
			imagenNueva: imagen,
			nombreImaNueva: RutaServ+fecha+NombreImagen+formato
		})
	}


	return(
		<div>
			<div>
				<br/>
				<h2>{Titulo}:</h2>	
			</div>
			<MDBContainer>
			  <MDBRow>
			    <MDBCol>
			      	<hr/>
			      	{error.activo ? 
						<span className="error"> Error: {error.mensaje}</span> : null
					}

			        <div className="row">
			        	<div className="col-md-9">

			        		<MDBInput label="Nombre Iglesia" outline 
					        	value={registroSelect.nombre}
								onChange={e => setRegistroSelect({...registroSelect, nombre: e.target.value})} 
							/>

			        		<div className="input-group">
							  	<div className="input-group-prepend">
							  	</div>
							  	<div className="custom-file">
							    	<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
							    		name="imagen" onChange={recibeImagen}
							    	/>
							    	<label className="custom-file-label">
							      		Seleccionar Logo Iglesia (400px x 400px)
							    	</label>
							  	</div>
							</div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <MDBInput label="Nit Iglesia" outline 
                                        value={registroSelect.nit}
                                        onChange={e => setRegistroSelect({...registroSelect, nit: e.target.value})} 
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <br></br>
                                <select 
                                    className="browser-default custom-select" 
                                    name="sede"
                                    value={registroSelect.tipo}
									onChange={e => setRegistroSelect({...registroSelect, tipo: e.target.value})}
                                    >
                                        <option value="">Selecciona Tipo de Iglesia</option>
                                        <option value="Central">Central</option>
                                        <option value="Filial">Filial</option>
                                </select>
                                </div>

                            </div>

			        	</div>

			        	<div className="col-md-3">

						<div className="FotoPrev">
					        			
					        			<img src={
					        				registroSelect.imagePrev === registroSelect.imagen ?
					        				baseUrl+"/"+registroSelect.imagePrev 
					        				: registroSelect.imagePrev
					        			}/>
					        		</div>
			        	</div>

			        </div>

                    <div className="row sinMargen">
                    <div className="col-md-6">
                            <MDBInput label="Ciudad" outline 
                                value={registroSelect.ciudad}
                                onChange={e => setRegistroSelect({...registroSelect, ciudad: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
                            <MDBInput label="Dirección" outline 
                                value={registroSelect.direccion}
                                onChange={e => setRegistroSelect({...registroSelect, direccion: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
							<MDBInput label="Pastor" outline 
                                value={registroSelect.pastor}
                                onChange={e => setRegistroSelect({...registroSelect, pastor: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
                            <MDBInput label="Teléfono" outline 
                                value={registroSelect.telefono}
                                onChange={e => setRegistroSelect({...registroSelect, telefono: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-12">
                            <MDBInput label="Denominación" outline 
                                value={registroSelect.denomina}
                                onChange={e => setRegistroSelect({...registroSelect, denomina: e.target.value})} 
                            />
                        </div>
                    </div>

                    

										
	
					<div className="text-center mt-4">
			          <div className="col text-center">	
						<button 
							onClick={peticionPut}
							className="btn boton_estandar"							
							>Subir Iglesia
						</button>
					</div>
			        </div>
			    </MDBCol>
			  </MDBRow>
			</MDBContainer>
		</div>
	)
}

export default EditarIglesias