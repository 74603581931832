import React, {useState,useEffect} from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { useNavigate } from 'react-router-dom';

//Componentes
import Cookies from 'universal-cookie'
import axios from 'axios'
import Logo from '../paginas/img/logo.png'
import Avatar from '../paginas/img/avatar.jpg'
import EstilosAdmin from '../paginas/estilos/estilos_admin.css'
import BotonAdmin from '../componentesAdmin/componentes/BotonAdmin.js'
import HeaderUserPrin from '../componentesAdmin/componentes/HeaderUserPrin.js'
import useTraeDatos from '../componentesAdmin/hooks/useTraeDatos';

//Modulos
import Escritorio from '../componentesAdmin/Escritorio'
import IglesiasAdmin from '../componentesAdmin/iglesias/IglesiasAdmin'
import TipoDonacion from '../componentesAdmin/tipodonaciones/TipoDonacion'
import UsuariosAdmin from '../componentesAdmin/usuarios/UsuariosAdmin'
import DonacionesAdmin from '../componentesAdmin/donaciones/DonacionesAdmin'
import MembresiaAdmin from '../componentesAdmin/membresia/MembresiaAdmin'
import MediosdepagoAdmin from '../componentesAdmin/mediosdepago/MediosdepagoAdmin'
import EscritorioAdmin from '../componentesAdmin/escritorio/EscritorioAdmin'
import GastosAdmin from '../componentesAdmin/gastos/GastosAdmin'
import CategoriasAdmin from '../componentesAdmin/categorias/CategoriasAdmin'
import InformesAdmin from '../componentesAdmin/informes/InformesAdmin'
import CalculadoraAdmin from '../componentesAdmin/calculadora/CalculadoraAdmin'


const Admin = ({_config}) => {

	//almacena toda la data de mediodePagos 
	const cookies = new Cookies()
	//console.log(cookies.get('nombre'))

	const Roll = cookies.get('roll')

	//const {baseUrl, titulo, sede, nombreImpuesto, urlProducto} = _config._config
	const baseUrl = 'https://iglesia.tecnosolagencia.com/'
	//const baseUrl = 'http://localhost:8888/'
	const titulo = "Iglesia Facil"
	const NombreUsuario = cookies.get('nombre')
	const UsuarioPadre = cookies.get('usuariopadre')
	const UsuarioActivo = cookies.get('usuario')
	const Denomina = cookies.get('denomina')
	const Iglesiasids = cookies.get('iglesiasids')
	//const history = useHistory();
	const navigate = useNavigate();

	//Urls

	const UrlIglesias = baseUrl+'apiIglesias/iglesias/'	
	const UrlTipoDonacion = baseUrl+'apiIglesias/tipodonacion/'	
	const urlUsuarios = baseUrl+'apiIglesias/usuarios/'	
	const UrlDonaciones = baseUrl+'apiIglesias/donaciones/'	
	const UrlMembresias = baseUrl+'apiIglesias/membresia/'	
	const UrlMediosdepago = baseUrl+'apiIglesias/mediosdepago/'	
	const UrlInformes = baseUrl+'apiIglesias/informes/'	
	const UrlGastos = baseUrl+'apiIglesias/gastos/'	
	const UrlCategorias = baseUrl+'apiIglesias/categorias/'	
	const UrlEscritorio = baseUrl+'apiIglesias/escritorio/'	
	
	//ESTADOS
	const [iglesias] = useTraeDatos(UrlIglesias, Iglesiasids, Roll)

	//---------------------PAGINA DE INICIO -----------------------
	const [seleccionado, setSeleccionado] = useState('Escritorio')
	//-------------------------------------------------------------

	const seleccionar = pagina => e => setSeleccionado(pagina)

	const cerrarSesion = (nombre) =>  e => {
		cookies.remove('id', {path: "/"})
		cookies.remove('nombre', {path: "/"})
		cookies.remove('usuario', {path: "/"})
		cookies.remove('iglesiasids', {path: "/"})
		cookies.remove('roll', {path: "/"})
		cookies.remove('usuariopadre', {path: "/"})
		navigate('/');
	}

		return(
			<div className="row">
				<div className="barra_izquierda col-xs-12 col-sm-12 col-md-3">
					
					<HeaderUserPrin
						Logo={Logo} 
						Avatar={Avatar}
						nombre={NombreUsuario} 
						Roll={Roll}
						Iglesiasids={Iglesiasids}
						denomina={Denomina}
						iglesias={iglesias}
					/>

					<BotonAdmin nombre={'Escritorio'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'}/>
					<BotonAdmin nombre={'Iglesias'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'}/>
					<BotonAdmin nombre={'Donaciones'} onClick={seleccionar} active={seleccionado} roll={Roll}/>

					<BotonAdmin nombre={'Tipo de Donación'} onClick={seleccionar} active={seleccionado}/>

					<BotonAdmin nombre={'Gastos'} onClick={seleccionar} active={seleccionado}/>
					<BotonAdmin nombre={'Categorías'} onClick={seleccionar} active={seleccionado}/>

					<BotonAdmin nombre={'Membresia'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'}/>
					
					<BotonAdmin nombre={'Informes'} onClick={seleccionar} active={seleccionado}/>
					<BotonAdmin nombre={'Medios de Pago'} onClick={seleccionar} active={seleccionado}/>
					<BotonAdmin nombre={'Usuarios'} onClick={seleccionar} active={seleccionado} roll={Roll} />
					<BotonAdmin nombre={'Calculadora'} onClick={seleccionar} active={seleccionado} roll={Roll} />
					<BotonAdmin nombre={'Cerrar Sesión'} onClick={cerrarSesion} active={seleccionado} roll={Roll} verPara={'Vendedor'}/>	
					
				</div>
				<div className="barra_derecha col-xs-12 col-sm-12 col-md-9">	
					
					<div className="contenedorAdmin">
						
						<div className="contenedor">

							{seleccionado ===  'Escritorio' ?
								<Escritorio 
									Titulo={'Escritorio'}/>
							:null}

							{seleccionado ===  'Iglesias' ?
								<IglesiasAdmin 
									urlIglesias={UrlIglesias}
									titulo={'Iglesias'}
									Roll={Roll}
									UsuarioPadre={UsuarioPadre}
									Iglesiasids={Iglesiasids}
									/>
							:null}

							{seleccionado === 'Tipo de Donación' ?
								<TipoDonacion 
									UrlTipoDonacion={UrlTipoDonacion}
									titulo={'Tipo de Donación'}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}/>
							:null}

							{seleccionado === 'Membresia' ?
								<MembresiaAdmin 
									baseUrl={UrlMembresias}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}
									/>
							:null}

							{seleccionado === 'Calculadora' ?
								<CalculadoraAdmin />
							:null}

							{seleccionado === 'Medios de Pago' ?
								<MediosdepagoAdmin 
									baseUrl={UrlMediosdepago}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}/>
							:null}

							{seleccionado === 'Informes' ?
								<InformesAdmin 
									baseUrl={UrlInformes}
									UrlMediosdepago={UrlMediosdepago}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}
									NombreUsuario={NombreUsuario}
									UrlGastos={UrlGastos}
									/>
							:null}

							{seleccionado === 'Escritorio' ?
								<EscritorioAdmin 
									baseUrl={UrlEscritorio}
									UrlMediosdepago={UrlMediosdepago}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}/>
							:null}


							{seleccionado === 'Gastos' ?
								<GastosAdmin 
									baseUrl={UrlGastos}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									UrlCategorias={UrlCategorias}
									Roll={Roll}
									UrlMediosdepago={UrlMediosdepago}/>
							:null}

							{seleccionado === 'Categorías' ?
								<CategoriasAdmin 
									baseUrl={UrlCategorias}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}
									UrlMediosdepago={UrlMediosdepago}/>
							:null}


							{seleccionado === 'Donaciones' || seleccionado === 'Ver Donaciones' ?
								<DonacionesAdmin 
									baseUrl={UrlDonaciones}
									titulo={'Donaciones'}
									iglesias={iglesias}
									Iglesiasids={Iglesiasids}
									Roll={Roll}
									baseUrlMiembros={UrlMembresias}
									UrlTipoDonacion={UrlTipoDonacion}
									UrlMediosdepago={UrlMediosdepago}
									/>
							:null}

							{seleccionado ===  'Usuarios' ?
								<UsuariosAdmin 
									urlUsuarios={urlUsuarios} 
									Titulo={'Usuarios'} 
									sedes={cookies.get('denomina')}
									UrlIglesias={UrlIglesias}
									Roll={Roll}
									Iglesiasids={Iglesiasids}
									UsuarioActivo={UsuarioActivo}
									UsuarioPadre={UsuarioPadre}/>
							:null}
						</div>	
					</div>	

				</div>			
			</div>
			
		)
}



export default Admin