import React from 'react'
import MaterialTable, { MTableAction } from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const Tabla = ({ columns, data, actions, title = '' }) => {

    return (
        <MaterialTable
            columns={columns}
            data={data}
            title={title}
            actions={actions}
            style={{fontSize: 15, background: 'transparent', border: 'none', borderStyle: 'none',boxShadow: 'none' }}

            localization={{

                body: {
                    emptyDataSourceMessage: 'No hay datos por mostrar',
                    addTooltip: 'Añadir',
                    deleteTooltip: 'Eliminar',
                    editTooltip: 'Editar',
                    filterRow: {
                        filterTooltip: 'Filtrar',
                    },
                    editRow: {
                        deleteText: '¿Segura(o) que quiere eliminar?',
                        cancelTooltip: 'Cancelar',
                        saveTooltip: 'Guardar',
                    },
                },
                grouping: {
                    placeholder: "Arrastre un encabezado aquí para agrupar",
                    groupedBy: 'Agrupado por',
                },
                header: {
                    actions: 'Acciones',
                },
                pagination: {
                    firstAriaLabel: 'Primera página',
                    firstTooltip: 'Primera página',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsPerPage: 'Filas por página:',
                    labelRowsSelect: 'filas',
                    lastAriaLabel: 'Ultima página',
                    lastTooltip: 'Ultima página',
                    nextAriaLabel: 'Pagina siguiente',
                    nextTooltip: 'Pagina siguiente',
                    previousAriaLabel: 'Pagina anterior',
                    previousTooltip: 'Pagina anterior',
                    labelRows: 'filas'
                },
                toolbar: {
                    addRemoveColumns: 'Agregar o eliminar columnas',
                    exportAriaLabel: 'Exportar',
                    exportName: 'Exportar a CSV',
                    exportTitle: 'Exportar',
                    nRowsSelected: '{0} filas seleccionadas',
                    searchPlaceholder: 'Buscar',
                    searchTooltip: 'Buscar',
                    showColumnsAriaLabel: 'Mostrar columnas',
                    showColumnsTitle: 'Mostrar columnas',
                },
            }}
            components={{
                Action: props => {

                    return < MTableAction {...props} />
                }
            }}
            options={{
                rowStyle: {
                    overflowWrap: 'break-word'
                },
                selection: false,
                filtering: false,
                sorting: false,
                exportAllData: true,
                idSynonym: 'unique_parent_id',
                actionsColumnIndex: -1,

                exportMenu: [{
                    label: 'Exportar PDF',
                    exportFunc: (cols, datas) => {
                        const dataExport = []
                        data.map((item) => {
                            let items = new Object();
                            cols.map(col => {
                                items[col.field] = item[col.field]
                            })
                            dataExport.push(items)
                        })
                        ExportPdf(cols, dataExport, title)
                    }
                }, {
                    label: 'Exportar CSV',
                    exportFunc: (cols, datas) => {
                        const dataExport = []
                        data.map((item) => {
                            let items = new Object();
                            cols.map(col => {
                                items[col.field] = item[col.field]
                            })
                            dataExport.push(items)
                        })
                        ExportCsv(cols, dataExport, title)
                    }
                }],
                paginationType: "stepped",
                pageSize: data.length <= 5 ? 5 : data.length <= 10 ? 10 : 20,

                thirdSortClick: false,
            }}
        />

    )
}



export default Tabla