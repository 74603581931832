import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt} from '@fortawesome/free-solid-svg-icons'

const IglesiasSelect = ({iglesiasSubidas, eliminaCombinacion, usuariopadre, Roll}) => {

    return (
        <div>
            {
                iglesiasSubidas.map((item, index) => {
                    return(

                        <div className="combinacion" key={index}> 
                            <div className="row">
                                <div className="col-md-9 NombreIgleCom">
                                    <h6>{item.nombre}</h6>
                                    <p><b>{item.denomina}</b></p>
                                    <p><b>Cod: </b>{item.id} <b>Ciudad: </b>{item.ciudad}</p>
                                    
                                </div>
                                <div className="col-md-3">
                                    {usuariopadre !== '' || Roll == 'superadmin'?
                                        <div>
                                            {Roll == 'Secretario' || Roll == 'Contador' ? null :
                                                <div className="col text-center">
                                                    <button 
                                                        onClick={eliminaCombinacion(index)}
                                                        className="btn boton_estandar_blanco"							
                                                        ><FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    :null}
                                </div>
                            </div>
                                
                            
                        </div>
                    )
                })
            }
        </div>
    )
}

export default IglesiasSelect
