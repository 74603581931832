import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'

const VerInformes = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {

	return (
		<MDBTable hover responsive>
			<HeaderTabla array={['No.', 'Fecha Informe', 'Fecha Ini', 'Fecha Fin', 'Total', 'Diezmos', 'Ofrendas','Firma','']} />
			<MDBTableBody>

				{data.map((item, index) => {
					return (
						<tr key={index} className="tabla_productos">
							<td>{item.id}</td>
							<td>{moment(item.fecha).format('DD-MM-YYYY')}</td>
							<td>{moment(item.fecha_inicio).format('DD-MM-YYYY')}</td>
							<td>{moment(item.fecha_fin).format('DD-MM-YYYY')}</td>
							<td><NumericFormat displayType="text" value={parseInt(item.total)} prefix={'$'} thousandSeparator={true} /></td>
							<td><NumericFormat displayType="text" value={parseInt(item.diezmo)} prefix={'$'} thousandSeparator={true} /></td>
							<td><NumericFormat displayType="text" value={parseInt(item.ofrenda)} prefix={'$'} thousandSeparator={true} /></td>
							<td>{item.nombre_usuario}</td>
							<td className="tabla_funciones">
								<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />
							</td>
						</tr>
					)
				})}

			</MDBTableBody>
		</MDBTable>
	)
}

export default VerInformes