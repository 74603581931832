import React, {useState, useEffect} from 'react'
import FormularioLogin from '../componentesAdmin/login/formulario_login.js'
import axios from 'axios'
import md5 from 'md5'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';

const Login = ({_config}) => {

	//const {baseUrl, titulo, sede} = _config._config
	// const baseUrl = 'http://localhost:8888/'
	const baseUrl = 'https://iglesia.tecnosolagencia.com/'
	const titulo = "Iglesia Facil"
	const sede = "Oasis"
	
	//estados:
	const baseUrlUsers = baseUrl+'apiIglesias/usuarios/';
	const cookies = new Cookies()
	const [form,setForm] = useState ({usuario: '', clave: ''})
	const [errorForm,setErrorForm] = useState ('')
	//trae información data api usuarios
	//const {loading, data, error} = useConsultaDatos(baseUrl);
	//funcion para navegar entre paginas
	//const history = useHistory();
	const navigate = useNavigate();


	//función click
	const onSubmit = async e => {
		e.preventDefault()

		await axios.get(baseUrlUsers, {params: {usuario: form.usuario, clave: md5(form.clave), unico:'true'}})
		.then (response => {
			let respuesta = response.data
			//console.log(respuesta)
			if(respuesta){
				if(respuesta.activo === 'true'){
					cookies.set('id', respuesta.id, {path: "/"})
					cookies.set('nombre', respuesta.nombre, {path: "/"})
					cookies.set('roll', respuesta.roll, {path: "/"})
					cookies.set('iglesiasids', respuesta.iglesiasids, {path: "/"})
					cookies.set('usuario', respuesta.usuario, {path: "/"})
					cookies.set('denomina', respuesta.denomina, {path: "/"})
					cookies.set('usuariopadre', respuesta.usuariopadre, {path: "/"})
					cookies.set('cierre', false, {path: "/"})

					navigate("../Admin");
				}
			}else{setErrorForm('Usuario o Contraseña Incorrecta')}
		})
		.catch(error =>{
			console.log(error)
			setErrorForm('No encuentra el servidor')
		})
	}

	//función escucha form
	const onChange = e => {
		setForm({...form, [e.target.name]: e.target.value})
	}

	useEffect(() => {
		document.title = "Iniciar sesión - "+titulo
		if(cookies.get('usuario')){
			navigate("../admin")
			//alert('correcto')
		}
	},[])	

		return(
			<div>
				<FormularioLogin 
						onChange={onChange}
						form={form}
						onSubmit={onSubmit}
						errorForm={errorForm}
						sede={sede}/>
			</div>
		)
}



export default Login