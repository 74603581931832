import React, {useEffect, useState} from 'react'

const SelectIglesiaBasico = ({iglesiaSelect, setIglesiaSelect, iglesias, Roll}) => {

    //const [IglesiasFull, setIglesiasFull] = useState([])

    // const selectIglesiaBasico = (ids) => {

    //     if(Roll == 'Admin'){
    //         //console.log(ids)
    //         let arrayIds = ids
    //         let arrayResult = []
    //         iglesias.map(item => {
    //             arrayIds.map(itemids => {
    //                 if(itemids == item.id)
    //                 arrayResult.push(item)
    //             })
    //         })
    //         //console.log(arrayResult)
    //         setIglesiasFull(arrayResult)
    //     }else if(Roll == 'superadmin'){
    //         setIglesiasFull(iglesias)
    //     }
	// }

    // useEffect(() => {
	// 	remplazaIdsaIglesias(Iglesiasids)
	// },[Iglesiasids])	

    return (
        <div>
            <select 
                className="browser-default custom-select" 
                name="ingrediente"
                value={iglesiaSelect.id}
                onChange={e => setIglesiaSelect({...iglesiaSelect, id: e.target.value})}
                >
                <option value="">Selecciona Iglesia</option>
                {iglesias.map((item, index) => {
                    return(
                        <option 
                            key={index}
                            value={item.id}>
                            {item.nombre}
                        </option>
                    )}
                )}
            </select><br></br>

        </div>
    )
}

export default SelectIglesiaBasico
