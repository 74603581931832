import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'


const SubirEditCargas = ({ baseUrl, data, setData, setFuncion, registroSelect, TituloBtn, funcion }) => {

	const [registro, setRegistro] = useState(funcion === 'editar' ? registroSelect : { activo: true, ids_tipoingresos: [], imagePrev: fotoVacia })
	const [error, setError] = useState({ activo: false })

	//Consulta para subir o crear los datos 

	const creaFormData = (registro, METHOD) => {

		let { id, nombre, cedula, telefono} = registro

		let f = new FormData()
		f.append('id', id)
		f.append('nombre', nombre)
		f.append('cedula', cedula)
		f.append('telefono', telefono)
		f.append('METHOD', METHOD)

		return f
	}

	const peticionPost = async () => {

		let { nombre, cedula, telefono } = registro

		if (!existeRegistro(data, nombre)) {
			if (nombre !== undefined && cedula !== undefined && telefono !== undefined) {

				let f = creaFormData(registro, 'POST')
				await axios.post(baseUrl, f)

					.then(response => {
						console.log('result', response.data)
						let resultFormat = { ...response.data }
						setData([resultFormat, ...data])
						setRegistro({ activo: false })
						errores('Limpio', setError)
						setFuncion('ver')

					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}

	const ProcedimientoPut = async () => {

		let {id, nombre, cedula, telefono } = registro

		let f = creaFormData(registro, 'PUT')
		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.cedula = cedula
						item.telefono = telefono
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {
		let {id, nombre, cedula, telefono } = registro
		if (nombre !== '' && cedula !== '' && telefono !== '') {
			ProcedimientoPut()
		} else errores('CampoVacio', setError)
	}


	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-12'>
							<MDBInput
									label="Nombre Completo" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

							<MDBInput
									label="Cédula" name="nombre" required className={error.cedula ? "form-control is-invalid" : null} outline
									value={registro.cedula}
									onChange={e => setRegistro({ ...registro, cedula: e.target.value })}
								/>

								<MDBInput
									label="Telefono" name="telefono" required className={error.telefono ? "form-control is-invalid" : null} outline
									value={registro.telefono}
									onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
								/>

							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditCargas