import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format'
import moment from 'moment'

const VerDonaciones = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {

	return (
		<MDBTable hover responsive>
			<HeaderTabla array={['Fecha', 'Nombre', 'Cedula', 'Total', 'Diezmo', 'Ofrenda', 'Medio de Pago', 'OfEsp', ' ']} />
			<MDBTableBody>

				{data.map((item, index) => {
					return (
						<tr key={index} className="tabla_productos">
							<td>{moment(item.fecha).format('DD-MM-YYYY')}</td>
							<td>{item.nombre}</td>
							<td>{item.cedula}</td>
							<td><NumericFormat displayType="text" value={parseInt(item.total)} prefix={'$'} thousandSeparator={true} /></td>
							<td><NumericFormat displayType="text" value={parseInt(item.diezmo)} prefix={'$'} thousandSeparator={true} /></td>
							<td><NumericFormat displayType="text" value={parseInt(item.ofrenda)} prefix={'$'} thousandSeparator={true} /></td>
							<td>{item.mediodepago}</td>
							<td>{item.tipoofrenda}</td>
							<td className="tabla_funciones">
								<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />
							</td>
						</tr>
					)
				})}

			</MDBTableBody>
		</MDBTable>
	)
}

export default VerDonaciones