import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'

const VerMediosdepago = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {

	return (
		<MDBTable hover responsive>
			<HeaderTabla array={['Id', 'Ima', 'Nombre', 'Color', '']} />
			<MDBTableBody>

				{data.map((item, index) => {
					return (
						<tr key={index} className="tabla_productos">
							<td>{item.id}</td>
							
							<td>
								{item.imagen !== '' ?
									<div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`} /></div>
								:
									<div className="ImgProductoSin"></div>
								}
							</td>
							<td>{item.nombre}</td>
							<td>{item.color}</td>
							<td className="tabla_funciones">
								<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />
							</td>
						</tr>
					)
				})}

			</MDBTableBody>
		</MDBTable>
	)
}

export default VerMediosdepago