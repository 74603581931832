import React, {useState, useEffect} from 'react'
import { MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput  } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faCheckCircle, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
//import NumberFormat from 'react-number-format';
import axios from 'axios'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import VerIglesias from '../../componentesAdmin/iglesias/VerIglesias'
import EditarIglesias from '../../componentesAdmin/iglesias/EditarIglesias'
import SubirIglesias from '../../componentesAdmin/iglesias/SubirIglesias'
import Eliminar  from '../componentes/Eliminar'
import moment from 'moment'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';

const IglesiasAdmin = ({urlIglesias, titulo, Roll, Iglesiasids}) => {

	const [registroSelect, setRegistroSelect] = useState({})
	//const [imagenPermanente, setImagenPermanente] = useState('')
	const [funcion, setFuncion] = useState('ver')
	const [iglesias, setIglesias] = useTraeDatos(urlIglesias, Iglesiasids, Roll)


	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDelete = async() => {
		let f = new FormData();
		f.append("METHOD", "DELETE");
		await axios.post(urlIglesias, f, {params: {id: registroSelect.id}})
		.then(response=>{setIglesias(iglesias.filter(iglesia=>iglesia.id!==registroSelect.id))
			setFuncion('ver')
		}).catch(error=>{ console.log(error)})
	}


	return(
		<div>
			<Header titulo={titulo} funcion={funcion} setFuncion={setFuncion} Roll={Roll} Pagina={'iglesias'}/>
            {funcion === 'crear' ?
                <SubirIglesias 
					baseUrl={urlIglesias} 
					setFuncion={setFuncion}
					iglesias={iglesias} 
					setIglesias={setIglesias}
					Titulo={'Subir Iglesia'} />

			:null}
			{funcion === 'editar' ?
				<EditarIglesias 
					baseUrl={urlIglesias} 
					setFuncion={setFuncion}
					iglesias={iglesias} 
					setIglesias={setIglesias}
					Titulo={'Editar Iglesia'} 
					registroSelect={registroSelect}
					setRegistroSelect={setRegistroSelect}
					/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Iglesia'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDelete}/>
				
			:null}
			{funcion === 'ver' ?
				<VerIglesias 
					data={iglesias} 
					urlIglesias={urlIglesias}
					seleccionaRegistro={seleccionaRegistro}
					Roll={Roll}/>
			:null}
		</div>
	)
}

export default IglesiasAdmin