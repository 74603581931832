import React, {useState, useEffect} from 'react'
import VerDonaciones from './VerDonaciones'
import SubirEditDonaciones from './SubirEditDonaciones'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'
import moment from 'moment'
import BusquedaFechas from '../componentes/BusquedaFechas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
import axios from 'axios';

const DonacionesAdmin = ({baseUrl, titulo, Iglesiasids, Roll, baseUrlMiembros, UrlTipoDonacion, UrlMediosdepago}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [donaciones, setDonaciones] = useState([])
	const [miembros] = useTraeDatos(baseUrlMiembros, '', Roll)
	const [tiposDeOfrenda] = useTraeDatos(UrlTipoDonacion, '', Roll)
	const [mediosDePago] = useTraeDatos(UrlMediosdepago, '', Roll)
	const [copiaDonaciones] = useTraeDatos(baseUrl)
	const [busqueda, setBusqueda] = useState('')
	const TitulosPagina = ['Ver Donaciones', 'Crear Donaciones', 'Editar Donaciones', 'Eliminar Donaciones', 'Donaciones']
	const [startDate, setStartDate] = useState(new Date());
	const [finalFecha, setFinalFecha] = useState(new Date());

	const peticionTraeDatos = async () => {
		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(baseUrl, { params: { tipo: 'simple', date: inicioFechaFormat } })
			.then(response => {
				let respuesta = response.data
				setDonaciones(respuesta)
				// setData(newArr)
				// setDataCopia(newArr)
				// setDataFiltrado([...newArr].splice(0, PAGINACION))
				// setNumPaginas(Math.ceil(newArr.length / PAGINACION))
				// setLoading(true)
				// setNumRegistros(newArr.length)
			})
			.catch(error => {
				console.log(error)
			})
	}

	const peticionTraeDatosAvanza = async () => {
		//setLoading(false)
		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(baseUrl, { params: { tipo: 'avanzada', date: inicioFechaFormat, date2: finalFechaFormat } })
			.then(response => {
				let respuesta = response.data

				setDonaciones(respuesta)
				// setDataCopia(newArr)
				// setDataFiltrado([...newArr].splice(0, PAGINACION))
				// setNumPaginas(Math.ceil(newArr.length / PAGINACION))
				// setNumRegistros(newArr.length)
				// setLoading(true)
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {peticionTraeDatos()}, [])
	useEffect(() => {peticionTraeDatosAvanza()}, [startDate, finalFecha])

	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaDonaciones.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase())){
				return item
			} 
		})
		setDonaciones(resultado)
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, baseUrl, donaciones, setDonaciones, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const ReiniciarPaginacion = () => {
		setDonaciones(copiaDonaciones)
		setBusqueda('')
	}

	return(
		<div>
			{/* <Header 
				titulo={tituloPag} 
				funcion={funcion} 
				setFuncion={setFuncion} 
				Pagina={'Cursos'} 
				RecibeBusqueda={RecibeBusqueda} 
				busqueda={busqueda}
				resetBusq={ReiniciarPaginacion}
			/> */}
			<div style={{ display: 'flex', width: '100%' }}>
				<div style={{ flex: 9 }}><br /><h2>{tituloPag}</h2></div>
				<div style={{ flex: 1 }}>
					<div className="filtroPedidos" style={{ display: 'flex', width: '100%', justifyContent: 'flexEnd' }}>
						{funcion === 'crear' || funcion === 'editar' ?
							<div style={{ justifyContent: 'flexEnd' }}>
								<button 
									onClick={e => setFuncion('ver')}
									className="btn boton_estandar btn-lg derecha">
									<FontAwesomeIcon icon={faTimes}/>
								</button>
							</div>
						:
							<React.Fragment>
								<BusquedaFechas
									startDate={startDate}
									setStartDate={setStartDate}
									finalFecha={finalFecha}
									setFinalFecha={setFinalFecha}
								/>
								<button 
										onClick={e => setFuncion('crear')}
										className="btn boton_estandar btn-lg derecha">
										<FontAwesomeIcon icon={faPlus}/>
								</button>
							</React.Fragment>
						}
					</div>
				</div>
			</div>
            {funcion === 'crear' ?
                <SubirEditDonaciones
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={donaciones} 
					setData={setDonaciones}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}
					miembros={miembros}
					tiposDeOfrenda={tiposDeOfrenda}
					mediosDePago={mediosDePago}
					baseUrlMiembros={baseUrlMiembros}
					/>
			:null}
			{funcion === 'editar' ?
				<SubirEditDonaciones
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={donaciones} 
					setData={setDonaciones}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					registroSelect={registroSelect}
					funcion={funcion}
					miembros={miembros}
					tiposDeOfrenda={tiposDeOfrenda}
					mediosDePago={mediosDePago}
					baseUrlMiembros={baseUrlMiembros}
					/>
			:null}
			{funcion === 'ver' ?
				<VerDonaciones
					data={donaciones} 
					seleccionaRegistro={seleccionaRegistro}
					baseUrl={baseUrl}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default DonacionesAdmin