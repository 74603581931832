import React, {useEffect, useState} from 'react'

const AsignarIglesiasSelect = ({iglesiaSelect, setIglesiaSelect, iglesias, subeIglesia, Roll, Iglesiasids, tipo}) => {

    const [IglesiasFull, setIglesiasFull] = useState([])

    const remplazaIdsaIglesias = (ids) => {

        if(Roll == 'Admin'){
            //console.log(ids)
            let arrayIds = ids
            let arrayResult = []
            iglesias.map(item => {
                arrayIds.map(itemids => {
                    if(itemids == item.id)
                    arrayResult.push(item)
                })
            })
            //console.log(arrayResult)
            setIglesiasFull(arrayResult)
        }else if(Roll == 'superadmin'){
            setIglesiasFull(iglesias)
        }
	}

    useEffect(() => {
		remplazaIdsaIglesias(Iglesiasids)
	},[Iglesiasids])	

    return (
        <div>
            <select 
                className="browser-default custom-select" 
                name="ingrediente"
                value={iglesiaSelect}
                onChange={e => setIglesiaSelect(e.target.value)}
                >
                <option value="">Selecciona Iglesia</option>
                {IglesiasFull.map((item, index) => {
                    return(
                        <option 
                            key={index}
                            value={item.nombre}>
                            {item.nombre}
                        </option>
                    )}
                )}
            </select><br></br>

            <div className="text-center mt-4">
                <div className="col text-center">	
                    <button 
                        onClick={subeIglesia}
                        className="btn boton_estandar"							
                        >Asignar Iglesia
                    </button>
                </div>
            </div>
            </div>
    )
}

export default AsignarIglesiasSelect
