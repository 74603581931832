import React from 'react'
//import Estilos from '../paginas/estilos/estilos.css'
import Estilos from '../../paginas/estilos/estilos.css'
import Logo from '../../paginas/img/logo.png'

const FormularioLogin = ({onChange, form, onSubmit, errorForm, sede}) => {

	return(
		<div className="Login">

			<div className="card align-self-center text-center container">
				<div className="card-body px-lg-5 pt-0">

				  	<img className="Logo_login" src={Logo} />

				  	<form className="text-center" onSubmit={onSubmit}>

					    <div className="md-form">
					        <input 
					        	type="text" 
					        	className="form-control"
					        	name="usuario"
					        	placeholder="Usuario"
					        	onChange={onChange}
					        	value={form.usuario}
					        />
					    </div>

					    <div className="md-form">
					        <input 
					        	type="password"
					        	className="form-control"
					        	name="clave"
					        	placeholder="Contraseña"
					        	onChange={onChange}
					        	value={form.clave}
					        />
						</div>
						<p className={errorForm !== '' ? 'animated bounceInRight' : ''}>{errorForm}</p>
						<button type="submit" className="btn boton_estandar btn-rounded btn-block my-4 btn-lg">Ingresar</button>
						<span>Sede: {sede}</span>
			    	</form>


				</div>
			</div>
		</div>
	)

}

export default FormularioLogin
