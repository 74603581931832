import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import fotoVacia from '../../paginas/img/fotoVacia.jpg'

const AutoCompleta = ({registro, setRegistro, productos, tipo, buscador, setBuscador}) => {

  const [activeCuadro, setActiveCuadro] = useState('hidden')
  const [placehold, setPlacehold] = useState('Buscar miembro')

  const [productosFil, setProductosFil] = useState([])

  const RecibeBusqueda = e => {
    setBuscador(e.target.value)
    filtrarGeneral(e.target.value)
    
  }

  const filtrar = (termino) => {
    let resultado = productos.filter((item) => {
      if (item.nombre_producto.toLowerCase().includes(termino.toLowerCase())) {
        return item
      }
    })
    setProductosFil(resultado)
  }

  const filtrarGeneral = (termino) => {
    const normalizar = (texto) => 
      texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  
    let resultado = productos.filter((item) => {
      if (normalizar(item.nombre).includes(normalizar(termino))) {
        return item;
      }
    });
    
    setProductosFil(resultado);
  }
  

  const SubeProducto = (encontrado) => {
    console.log('sel', {...registro, ...encontrado})
    //setBuscador(encontrado.nombre)
    setBuscador('')
    setPlacehold(encontrado.nombre)
    setRegistro({...registro, ...encontrado, id_miembro: encontrado.id, miembroExist: true})
    
  } 

  return (
    <div>
      <div className="form-group">
        <label>{`Nombre Completo`}:</label>
          <input 
              onChange={RecibeBusqueda} 
              value={buscador} 
              type="buscador" className="form-control" id="buscador" placeholder={placehold}>
          </input>
        <div className={buscador.length > 0 ? `cuadroBusqueda` : `hidden`} >
            <div>
                {productosFil.length > 0 ?
                      <div>
                            {productosFil.map(item => {
                              return (
                                <div key={item.id}>
                                  <button className='ProductMin' onClick={e => SubeProducto(item)}>
                                    <div className='textProdMin'>
                                      <h6>{item.nombre}</h6>
                                      <p>CC:{item.cedula} - Cel: {item.telefono}</p>
                                    </div>

                                  </button>
                                  <hr></hr>
                                </div>
                              )
                            })}
                      </div>
                      :<p>{tipo} no encontrado...</p>
                  }
            </div>
          
        </div>
      </div>

    </div>
  )
}

export default AutoCompleta