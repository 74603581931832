import React, { useState, useEffect } from 'react'
import VerCategorias from './VerCategorias'
import SubirEditCategorias from './SubirEditCategorias'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'
import moment from 'moment'

const CategoriasAdmin = ({ baseUrl, urlcategorias, titulo, UrlMediosdepago }) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [gastos, setGastos] = useTraeDatos(baseUrl)
	const [mediosdepago] = useTraeDatos(UrlMediosdepago)
	const TitulosPagina = ['Ver Categorías', 'Crear Categoría', 'Editar Categoría', 'Eliminar Categoría', 'Categorías']
	const [copiaArray, setCopiaArray] = useTraeDatos(baseUrl)
	const [busqueda, setBusqueda] = useState('')

	const seleccionaRegistro = (dato, funcion) => e => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen, fechatermino: moment(dato.fechatermino, 'YYYY-MM-DD').toDate() })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => { peticionDelete(registroSelect, baseUrl, gastos, setGastos, setFuncion) }
	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaArray.filter((item) => {
			if (item.nombre.toLowerCase().includes(termino.toLowerCase())) {
				return item
			}
		})
		setGastos(resultado)
	}

	const ReiniciarPaginacion = () => {
		setGastos(copiaArray)
		setBusqueda('')
	}


	return (
		<div>
			<Header 
				titulo={tituloPag} 
				funcion={funcion} 
				setFuncion={setFuncion} 
				Pagina={'gastos'} 
				RecibeBusqueda={RecibeBusqueda} 
				busqueda={busqueda} 
				resetBusq={ReiniciarPaginacion}
			/>
			{funcion === 'crear' ?
				<SubirEditCategorias
					baseUrl={baseUrl}
					baseUrCategorias={urlcategorias}
					setFuncion={setFuncion}
					data={gastos}
					setData={setGastos}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					funcion={funcion} 
					medios={mediosdepago}
					/>
				: null}
			{funcion === 'editar' ?
				<SubirEditCategorias
					baseUrl={baseUrl}
					baseUrCategorias={urlcategorias}
					setFuncion={setFuncion}
					data={gastos}
					setData={setGastos}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					registroSelect={registroSelect}
					funcion={funcion} 
					medios={mediosdepago}
					/>
				: null}
			{funcion === 'ver' ?
				<VerCategorias
					data={gastos}
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
					baseUrl={baseUrl}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default CategoriasAdmin