import React, {useState, useEffect} from 'react'
import VerInformes from './VerEscritorio'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'
import axios from 'axios'
import moment from 'moment'

const EscritorioAdmin = ({baseUrl, UrlMediosdepago, titulo, Iglesiasids, Roll}) => {


	const [funcion, setFuncion] = useState('ver')
	const [totalDonaciones, setTotalDonaciones] = useState({resultDona: 0, totaldiez: 0, totalofren: 0})
	const [totalGastos, setTotalGastos] = useState(0)
	const [mediosFormat, setMediosFormat] = useState([])
	const [estadisticas, setEstadisticas] = useState([])
	const [categorias, setCategorias] = useState([])

	const peticionTraeMedios = async (informesTrae) =>{

			await axios.get(`${UrlMediosdepago}/gastos.php`)
			.then (response => {
				let medios = []
				medios = response.data

				const MediosFull = []
				medios.map(item =>{
					informesTrae.map(itemm =>{
						if (item.nombre === itemm.medio_de_pago){
							MediosFull.push({nombre: item.nombre, total: parseInt(itemm.total_sum) -  parseInt(item.total_gastos !== null ? item.total_gastos : 0), color: item.color, imagen: item.imagen})
						}
					})
				})
				setMediosFormat(MediosFull)
			}).catch(error =>{
				console.log(error)
			})

	} 



	const peticionCategorias = async () =>{

		let inicioFechaFormat = moment('2024').format("YYYY")
		await axios.get(`${baseUrl}/categorias.php`, {params: {tipo: 'simple', date: inicioFechaFormat}})
		.then (response => {	
			let cats = response.data
			console.log('categorias', cats)

			let translatedData = cats.map(item => ({
				...item,
				mes: monthTranslation[item.mes] || item.mes, // Traduce si existe en el mapa
			  }));


			setCategorias(translatedData)

		}).catch(error =>{
			console.log(error)
		})

	} 


	const peticionEstadisticas = async () =>{

		let inicioFechaFormat = moment('2024').format("YYYY")

		await axios.get(`${baseUrl}/estadisticas.php`, {params: {tipo: 'simple', date: inicioFechaFormat}})
		.then (response => {	
			let estadistic = response.data

			let translatedData = estadistic.map(item => ({
				...item,
				mes: monthTranslation[item.mes] || item.mes, // Traduce si existe en el mapa
			  }));

			  setEstadisticas(translatedData)


		}).catch(error =>{
			console.log(error)
		})

	} 

	const peticionTraeInformes = async () =>{

		await axios.get(baseUrl)
		.then (response => {
			let informes = response.data

			let resultDona = 0
			let totaldiez = 0
			let totalofren = 0
			let resultGas = 0
			informes.map(item =>{
				if (item.medio_de_pago === 'Total donaciones'){
					resultDona = item.total_sum
					totaldiez = item.diezmo_sum
					totalofren = item.ofrenda_sum
				}
			})

			informes.map(item =>{if (item.medio_de_pago === 'Total gastos'){resultGas = item.total_sum}})

			setTotalDonaciones({resultDona, totaldiez, totalofren })
			peticionTraeMedios(informes)

			setTotalGastos(resultGas)

		}).catch(error =>{
			console.log(error)
		})

	} 

	const monthTranslation = {
		January: 'Enero',
		February: 'Febrero',
		March: 'Marzo',
		April: 'Abril',
		May: 'Mayo',
		June: 'Junio',
		July: 'Julio',
		August: 'Agosto',
		September: 'Septiembre',
		October: 'Octubre',
		November: 'Noviembre',
		December: 'Diciembre',
	  };

	useEffect(()=>{
		peticionTraeInformes()
		peticionTraeMedios()
		peticionEstadisticas()
		peticionCategorias()
	},[])


	return(
		<div>

			{funcion === 'ver' ?
				<div>
					<VerInformes
						totalDonaciones={totalDonaciones} 
						totalGastos={totalGastos}
						mediosFormat={mediosFormat}
						UrlMediosdepago={UrlMediosdepago}
						estadisticas={estadisticas}
						categorias={categorias}
					/>
				</div>
			:null}
		</div>
	)
}

export default EscritorioAdmin