import React, {useState, useEffect} from 'react'
//import VerUsuarios from '../componentesAdmin/usuarios/VerUsuarios.js'
import VerUsuarios from '../usuarios/VerUsuarios'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import Header from '../componentes/Header';
import Eliminar  from '../componentes/Eliminar'
import SubirUsuario from './SubirUsuario'
import EditarUsuario from './EditarUsuario'
import useTraeDatos from '../hooks/useTraeDatos';

const UsuariosAdmin = ({urlUsuarios, Titulo, sedes, UrlIglesias, Roll, Iglesiasids, UsuarioPadre, UsuarioActivo}) => {

	//almacena toda la data de categorias 
	const [iglesias, setIglesias] = useTraeDatos(UrlIglesias)
	const [error, setError] = useState({activo: false, mensaje: ''})
	const [data, setData] = useState([])
	const [iglesiasSubidas, setIglesiasSubidas] = useState([])
	//almacena el campo Nombre de categoria
	const [usuario, setUsuario] = useState('')
	const [nombreUsuario, setNombreUsuario] = useState('')
	const [clave, setClave] = useState('')
	//almacena el campo activo de categoria
	const [activo, setActivo] = useState(true)
	//almacena la categoria seleccionada
	const [usuarioSelect, setUsuarioSelect] = useState({})
	//selecciona la función como eliminar, crear, editar
	const [funcion, setFuncion] = useState('ver')
	//Alerta del error para la validacion de formulario
	const [alerta, setAlerta] = useState(false)

	const [sede, setSede] = useState('')

	const [roll, setRoll] = useState('')

	const errores = codigo => {
		switch (codigo){
			case 'UserExiste':
				setError({activo: true, mensaje: 'el usuario ya existe', campoUsuario: true})
				break 
			case 'CampoVacio':
				setError({activo: true, mensaje: 'Hay campos vacios'})
				break 
			case 'Limpio':
				setError({activo: false, mensaje: '',campoUsuario: false,
					campoClave: false,
					campoNombreUser: false,
					campoRoll: false,
					campoSede: false})
				break 
				
		}
	}

	//actualiza el registro de la categoria seleccionada
	const actualizarDato = (dato, name) =>{
		setUsuarioSelect({...usuarioSelect, [name]: dato })
	}

	const peticionTraeDatosUsers = async(User)=>{

		await axios.get(urlUsuarios, {params: {id: '14', usuario: User, clave:'1', unico:'false', roll: Roll}})
		.then (response => {
			setData(response.data)
		}).catch(error =>{
			console.log(error)
		})

	} 

	const peticionTraeDatos = () =>{

		if(Roll == 'Contador' || Roll == 'Secretario'){
			peticionTraeDatosUsers(UsuarioActivo)
		}else if (UsuarioPadre == '' || UsuarioPadre == undefined){
			peticionTraeDatosUsers(UsuarioActivo)
		}else{
			peticionTraeDatosUsers(UsuarioPadre)
		}

	}

	//realiza petición que borra de la base de datos por medio de la ID
	const peticionDelete=async()=>{
	    let f = new FormData();
	    f.append("METHOD", "DELETE");
	    await axios.post(urlUsuarios, f, {params: {id: usuarioSelect.id}})
	    .then(response=>{
	      setData(data.filter(categoria=>categoria.id!==usuarioSelect.id))
	      setFuncion('ver')
	    }).catch(error=>{
	      console.log(error)
	    })
	  }

	//Selecciona categoria para editarla o borrarla 
	const Seleccionar = (objeto, funcion) => e => {
		setFuncion(funcion)
		setUsuarioSelect({
			id: objeto.id,
			activo: objeto.activo === 'true' ? true : false,
			usuario: objeto.usuario,
			clave: objeto.clave,
			nombre: objeto.nombre,
			iglesiasids: objeto.iglesiasids,
			roll: objeto.roll,
			telefono: objeto.telefono,
			denomina: objeto.denomina,
			usuariopadre: objeto.usuariopadre,
			claveNueva: ''

		})
		if(funcion === 'editar'){
			setFuncion('editar')
		}
	}


	const DepuraIds = array =>{

		let Arr2 = []
		array.map(item => {
			Arr2.push(item.id)
		})
		const result = Arr2.filter((valor, indice) => {
			return Arr2.indexOf(valor) === indice;
		  });
		return result
	}

	//Consulta para editar campos
	const peticionPut=async()=>{
		const {activo, usuario, clave, claveNueva, nombre, telefono, roll, denomina} = usuarioSelect
		const idsIglesias = DepuraIds(iglesiasSubidas)
		let UsuarioPadreFull = ''

		if(Roll == 'superadmin'){
			// console.log('padre global:' ,UsuarioPadre)
			// console.log('padre nuevo:' ,usuarioSelect.usuariopadre)
			if(UsuarioPadre !== ''){
				UsuarioPadreFull = usuarioSelect.usuariopadre
				console.log('arriba:')
			}else if (usuarioSelect.usuariopadre !== '') {
				UsuarioPadreFull = usuarioSelect.usuariopadre
				console.log('abajo:')
			}else if (usuarioSelect.usuariopadre !== 'Principal') {
				UsuarioPadreFull = ''
			}

		}else if (Roll == 'Admin'){
			if(UsuarioPadre !== ''){
				UsuarioPadreFull = usuarioSelect.usuariopadre
				console.log('arriba:')
			}else{
				UsuarioPadreFull = UsuarioActivo
				console.log('abajo:')
				console.log('abajo:', UsuarioActivo)
			}
		}
		else if (Roll == 'Contador' || Roll == 'Secretario'){
			UsuarioPadreFull = usuarioSelect.usuariopadre
		}


		if(usuario !== '' && clave !== '' && nombre !== '' && telefono !== '' && roll !== '' && denomina !== ''  && idsIglesias.length > 0 ){
			    let f = new FormData()
				f.append('activo', activo.toString())
				f.append('usuario', usuario)
				f.append('clave', claveNueva !== "" ? claveNueva : '')
				f.append('nombre', nombre)
				f.append('telefono', telefono)
				f.append('roll', roll)
				f.append('iglesiasids', JSON.stringify(idsIglesias))
				f.append('denomina', denomina)
				f.append('usuariopadre', UsuarioPadreFull)
				f.append('METHOD', 'PUT')

			    await axios.post(urlUsuarios, f, {params: {id: usuarioSelect.id}})
			    .then(response=>{
				  let dataNueva = data
						dataNueva.map(item=>{
						if(item.id === usuarioSelect.id){
							item.usuario = usuario
							item.clave = clave
							item.nombre = nombre
							item.telefono = telefono
							item.roll = roll
							item.iglesiasids = JSON.stringify(idsIglesias)
							item.denomina = denomina
							item.usuariopadre = UsuarioPadreFull
						}
					})
				  setData(dataNueva)
			      setUsuarioSelect({activo:true, iglesiasids: 0})
				  //setIglesiaSelect('')
				  //setIglesiasSubidas([])
				  setFuncion('ver')
			      errores('Limpio')
				  console.log('paso')
			    }).catch(error=>{
			      console.log(error);
			    })
			}else errores('CampoVacio')
	}

	const existeUsuario = user => {

		let resultado = false
		data.map((item) => {
			let {usuario} = item
			if(usuario === user)
				resultado = true
		})
		return resultado
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () =>{
		if(!existeUsuario(usuario)){
			if(usuario !== '' && clave !== '' && nombreUsuario !== '' && sede !== '' && roll !== ''){
				let f = new FormData()
				f.append('activo', activo.toString())
				f.append('usuario', usuario)
				f.append('clave', clave)
				f.append('roll', roll)
				f.append('nombre_usuario', nombreUsuario)
				f.append('sede', sede)
				f.append('METHOD', 'POST')
				console.log(activo)
				console.log(usuario)
				await axios.post(urlUsuarios, f)

				.then(response=>{
			      setData(data.concat(response.data));
			      setUsuario('')
			      setNombreUsuario('')
				  setClave('')
				  setActivo('')
				  setUsuarioSelect('')
				  setSede('')
				  setRoll('')
			      setActivo(false)
			      errores('Limpio')
			    }).catch(error=>{
			      console.log(error);
			    })
			}else errores('CampoVacio')
		 }else errores('UserExiste')
		
	}

	//Trae todas las categorias de la api
	useEffect(() => {
		peticionTraeDatos()
	},[])		


	return(
		<div>
			<Header titulo={Titulo} funcion={funcion} setFuncion={setFuncion} />
			<div className="row">
				<div className="col-md-12">

					{funcion === 'editar' ?
						<EditarUsuario
							usuarios={data} 
							usuarioSelect={usuarioSelect}
							setUsuarioSelect={setUsuarioSelect}
							setUsuarios={setData}
							Titulo={'Usuarios'}
							iglesias={iglesias}
							setFuncion={setFuncion}
							peticionPut={peticionPut}
							Roll={Roll}
							iglesiasSubidas={iglesiasSubidas}
							setIglesiasSubidas={setIglesiasSubidas}
							Iglesiasids={Iglesiasids}
						/>
					: null}
					{funcion === 'crear' ?
						<SubirUsuario
							urlUsuarios={urlUsuarios} 
							usuarios={data} 
							setUsuarios={setData}
							Titulo={'Usuarios'}
							iglesias={iglesias}
							setFuncion={setFuncion}
							Roll={Roll}
							Iglesiasids={Iglesiasids}
							UsuarioActivo={UsuarioActivo}
							UsuarioPadre={UsuarioPadre}
						/>
					:null}
					{funcion === 'eliminar' ?
						<Eliminar 
							nombre={'Usuario'}
							select={usuarioSelect}
							setFuncion={setFuncion}
							peticionDelete={peticionDelete}/>
					:null}
					{funcion === 'ver' ?
						<VerUsuarios
							data={data} 
							Seleccionar={Seleccionar}
							Roll={Roll}
							iglesias={iglesias}
						/>
					:null}
				</div>
			</div>
		</div>
	)
}

export default UsuariosAdmin