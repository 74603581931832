import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalculator, faTag, faMoneyBill, faPerson, faWallet, faCoins, faTachometerAlt, faBuilding, faArrowRight, faCreditCardAlt, faChartBar, faCogs, faSignOut, faUserFriends, faUsers} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUserFriends, faArrowRight, faShoppingCart, faCashRegister, faIceCream, faCogs, faStore, faSignOutAlt, faCreditCard, faUsers, faChartBar, faClipboardList, faTachometerAlt, faKey, faMotorcycle} from '@fortawesome/free-solid-svg-icons'


const BotonAdmin = ({nombre, onClick, sub, active, roll, verPara}) => {


	const EncuentraIcono = icon => {

		switch(icon){
			case 'Escritorio': return faTachometerAlt
			case 'Iglesias': return faBuilding
			case 'Donaciones': return faMoneyBill
			case 'Tipo de Donación': return faCoins
			case 'Membresía': return faUsers
			case 'Estadísticas': return faChartBar 
			case 'Informes': return faChartBar
			case 'Usuarios': return faUserFriends
			case 'Membresia': return faPerson
			case 'Medios de Pago': return faCreditCardAlt
			case 'Configuración': return faCogs
			case 'Cerrar Sesión': return faSignOut
			case 'Gastos': return faWallet
			case 'Calculadora': return faCalculator
			case 'Categorías': return faTag
			// case 'Clientes': return faUsers
			// case 'Cierres de Caja': return faKey
			default: return faArrowRight
		}
	}


	return(

			<button  
				// className={sub ? "sub-item" : "item"}
				style={roll === verPara || roll === 'Admin' ? null : null}
				className={active === nombre ? sub ? "sub-item active" : "item active" : sub ? "sub-item" : "item"}
				onClick={onClick(nombre)}>	
					<FontAwesomeIcon icon={EncuentraIcono(nombre)} size="1x"/>
					{' '+nombre}
			</button>
	)
}



export default BotonAdmin