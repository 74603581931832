import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const BotonesFormulario = ({item, SeleccionarCategoria, Roll, acceso}) => {

	return(
        <div className="col text-center">	
            <button 
                onClick={SeleccionarCategoria(item, 'editar')}
                className="btn boton_estandar_blanco"							
                ><FontAwesomeIcon icon={faEdit} size="lg"/>
            </button>

            {Roll == 'superadmin' || Roll == 'Admin' || acceso == 'full' ? 
                <button 
                    onClick={SeleccionarCategoria(item, 'eliminar')}
                    className="btn boton_estandar_blanco"							
                    ><FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                </button>
            :null}

            
        </div>
	)
}



export default BotonesFormulario