import React, {useState, useEffect} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const SubirIglesias = ({baseUrl, setFuncion, iglesias, setIglesias, Titulo}) => {

    const [registro, setRegistro] = useState({})


	const [error, setError] = useState({
		activo: false,
		mensaje: 'Hola'
	})

	const errores = codigo => {
		switch (codigo){
			case 'CampoVacio':
				alert('Hay campos vacios')
				//setError({activo: true, mensaje: 'Hay campos vacios'})
				break 
			case 'Precio':
				alert('El Campo Precio debe ser diferente al de reabaja')
				//setError({activo: true, mensaje: 'El Campo Precio debe ser diferente al de reabaja'})
				break 
			case 'Imagen':
				alert('El archivo pesa más de 2mb o tiene un formato no admitido')
				//setError({activo: true, mensaje: 'El archivo pesa más de 2mb o tiene un formato no admitido'})
				break
			case 'Limpio':
				setError({activo: false, mensaje: ''})
				break 
				
		}
	}

	const peticionPost = async () =>{

		const fecha = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        let {activo, nombre, nit, tipo, ciudad, direccion, denominacion, pastor, telefono, idpadre, imagen} = registro


		let formato = imagen.type.replace('image/','.');
		let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
		let RutaServ = 'images/'
		console.log('nombreImagenFormat: '+fecha+NombreImagen+formato)

		let formatosPermitidos = ['.png','.jpeg','.jpg']

		if(registro.nombre !== '' && registro.pastor !== '' && imagen !== null){
				if(imagen.size < 2000000 && formatosPermitidos.includes(formato)){
					let f = new FormData()

					let NombreIma = RutaServ+fecha+NombreImagen+formato
					f.append('activo', 'true')
                    f.append('fecha', fecha)
                    f.append('nombre', nombre)
					f.append('nit', nit)
					f.append('tipo', tipo)
					f.append('ciudad', ciudad)
                    f.append('direccion', direccion)
                    f.append('denomina', denominacion)
                    f.append('pastor', pastor)
                    f.append('telefono', telefono)
                    f.append('imagen', imagen)
					f.append('nombre_imagen', NombreIma)
					f.append('METHOD', 'POST')

					await axios.post(baseUrl, f)

					.then(response=>{
						//let result = registro.concat(response.data)
						//console.log('result', result)
                        console.log('result', response.data)

						let resultFormat = {...response.data, imagePrev: NombreIma, imagen: NombreIma}

						//setIglesias(iglesias.concat(resultFormat));
						setIglesias([resultFormat, ...iglesias]);
						// setIngrediente({
						// 	nombre: '',
						// 	cantidad: 0,
						// 	medida: '', 
						// 	imagen: null,
						// 	imagePrev: null
						// })

						// setAgotado({nombre: 'Agotado', rangofin: 0})
						// setPocasExis({nombre: 'Pocas Existencias', rangoini: 0, rangofin: 0})
						// setconExist({nombre: 'En Existencia', rangoini: 0})

					  console.log('enviado')
				      errores('Limpio')
				    }).catch(error=>{
				      console.log('muestra erro: '+error);
				    })
					setFuncion('ver')
			    }else errores('Imagen')
		}else errores('CampoVacio')
		
	}

	const recibeImagen = e => {
		let picture = e.target.files[0]
		setRegistro({...registro, imagePrev: URL.createObjectURL(picture), imagen: picture})
		console.log('Imagen: '+ picture)
	}


	return(
		<div>
			<div>
				<br/>
				<h2>{Titulo}:</h2>	
			</div>
			<MDBContainer>
			  <MDBRow>
			    <MDBCol>
			      	<hr/>
			      	{error.activo ? 
						<span className="error"> Error: {error.mensaje}</span> : null
					}

			        <div className="row">
			        	<div className="col-md-9">

			        		<MDBInput label="Nombre Iglesia" outline 
					        	value={registro.nombre}
								onChange={e => setRegistro({...registro, nombre: e.target.value})} 
							/>

			        		<div className="input-group">
							  	<div className="input-group-prepend">
							  	</div>
							  	<div className="custom-file">
							    	<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
							    		name="imagen" onChange={recibeImagen}
							    	/>
							    	<label className="custom-file-label">
							      		Seleccionar Logo Iglesia (400px x 400px)
							    	</label>
							  	</div>
							</div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <MDBInput label="Nit Iglesia" outline 
                                        value={registro.nit}
                                        onChange={e => setRegistro({...registro, nit: e.target.value})} 
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <br></br>
                                <select 
                                    className="browser-default custom-select" 
                                    name="sede"
                                    value={registro.tipo}
									onChange={e => setRegistro({...registro, tipo: e.target.value})}
                                    >
                                        <option value="">Selecciona Tipo de Iglesia</option>
                                        <option value="Central">Central</option>
                                        <option value="Filial">Filial</option>
                                </select>
                                </div>

                            </div>

			        	</div>

			        	<div className="col-md-3">

			        		<div className="FotoPrev">
			        			<img src={registro.imagePrev !== null ? registro.imagePrev : fotoVacia}/>
			        		</div>
			        	</div>

			        </div>

                    <div className="row sinMargen">
                    <div className="col-md-6">
                            <MDBInput label="Ciudad" outline 
                                value={registro.ciudad}
                                onChange={e => setRegistro({...registro, ciudad: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
                            <MDBInput label="Dirección" outline 
                                value={registro.direccion}
                                onChange={e => setRegistro({...registro, direccion: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
							<MDBInput label="Pastor" outline 
                                value={registro.pastor}
                                onChange={e => setRegistro({...registro, pastor: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-6">
                            <MDBInput label="Teléfono" outline 
                                value={registro.telefono}
                                onChange={e => setRegistro({...registro, telefono: e.target.value})} 
                            />
                        </div>
                        <div className="col-md-12">
                            <MDBInput label="Denominación" outline 
                                value={registro.denominacion}
                                onChange={e => setRegistro({...registro, denominacion: e.target.value})} 
                            />
                        </div>
                    </div>

                    

										
	
					<div className="text-center mt-4">
			          <div className="col text-center">	
						<button 
							onClick={peticionPost}
							className="btn boton_estandar"							
							>Subir Iglesia
						</button>
					</div>
			        </div>
			    </MDBCol>
			  </MDBRow>
			</MDBContainer>
		</div>
	)
}

export default SubirIglesias