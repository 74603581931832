import React from "react";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
//import estilos from'../../paginas/estilos/estilos_admin.css'; // Ruta relativa al archivo CSS
import EstilosAdmin from '../../paginas/estilos/estilos_admin.css'

class ComponentToPrintDomi extends React.Component {
  render() {

    const data = this.props.data
    return (
        <div className="impresionPdf pagebreak">
          <br></br>
            {/* <h2><img src={this.props.logo} className="logoPdf" /> Informe de Domicilios: Total <NumberFormat value={this.props.totalDomi} displayType={'text'} thousandSeparator={true} prefix={'$'}/></h2> */}

            
            <div className="row">
                <div className="col-md-12" >
                  <div><hr />
                      <div style={{border: '1px solid #000'}}>
                        <div style={{paddingTop: 10, display: 'flex'}} >
                            <div style={{flex:1}}>
                              <div style={{paddingLeft: 10}}>
                                <center>
                                <img className="Logoinforme" src={this.props.ima} alt="Logo" />
                                </center>
                                
                              </div>
                            </div>
                            <div style={{flex:5}}>
                              <span>CONTROL DE DIEZMOS Y OFRENDAS</span><br/>
                              <span style={{fontSize:20, fontWeight: 400}}>IGLESIA CENTRO DE ADORACIÓN FAMILIAR OASIS CALI</span><br/>
                              <span style={{fontWeight: 500, fontSize: 17}}><b>Fecha de Informe de: </b> {moment(this.props.fechaIni).format("DD-MM-YYYY")} a {moment(this.props.fechaFin).format("DD-MM-YYYY")}</span><br/>
                            </div>
                            <div style={{flex:2}}>
                              <span><b>Creado el: </b></span>
                              <span>{moment(this.props.fechahoy).format("DD-MM-YYYY")}</span><br />
                              <span style={{color: 'red', fontWeight: 400, marginBottom: -50}}>No.</span>
                              <span style={{color: 'red', fontWeight: 500, fontSize: 30}}>
                                {this.props.ultimoID} 
                              </span>
                            </div>
                        </div>



                        <div style={{paddingTop: 10, display: 'flex'}} >
                            <div style={{flex:1}}>
                              <div style={{background: '#fff4ca', padding: 5}}>
                                <span><b>Fechas Seleccinadas:</b></span>
                              </div>

                              <div style={{padding: 5, width: '100%'}}>
                                <table style={{width: '100%'}}>
                                <thead>
                                  <tr>
                                  <th style={{backgroundColor: '#fff4ca', padding: 5, flex: 1}}>Fechas</th>
                                  <th style={{backgroundColor: '#fff4ca', padding: 5, flex: 1}}>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                  {this.props.informeSumafechas.map((item, index) =>{
                                    return(
                                      <tr key={index}>
                                        <td><span>{item.fecha}</span></td>
                                        <td><NumericFormat displayType="text" value={parseInt(item.sum_total)} prefix={'$'} thousandSeparator={true} /></td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                                </table>
                              </div>
                            </div>
                            <div style={{flex:1}}>
                              <div style={{backgroundColor: '#93c1e8', padding: 5}}>
                                <span><b>Donaciones, Diezmos, Ofrendas, Protemplos:</b></span>
                              </div>
                              <div style={{padding: 5}}>
                                <span>Total Diezmos: <NumericFormat displayType="text" value={this.props.totales.totaldiezmos} prefix={'$'} thousandSeparator={true} /></span><br />
                                <span>Total Ofrendas: <NumericFormat displayType="text" value={this.props.totales.totalofrendas - this.props.totales.ofrendaseps} prefix={'$'} thousandSeparator={true} /></span><br />
                                {this.props.informeSumaTiposOfrenda.map(item =>{
                                  if(item.tipo_donacion !== 'Total General'){
                                    return(
                                      <div><span>{item.tipo_donacion}: <NumericFormat displayType="text" value={item.suma_ofrenda} prefix={'$'} thousandSeparator={true} /></span><br /></div>
                                    )
                                  }
                                })}
                                <span style={{fontWeight: 500, fontSize: 17}}>Total: <NumericFormat displayType="text" value={this.props.totales.totaltotales} prefix={'$'} thousandSeparator={true} /></span><br />
                              </div>
                            </div>


                            <div style={{flex:1}}>
                              <div style={{backgroundColor: '#ddf1da', padding: 5}}>
                                <span><b>Medios de Pago:</b></span>
                              </div>
                              <div style={{padding: 5}}>
                                {this.props.mediosdepagoOK.map(item =>{
                                    return(
                                      <div><span>{item.mediodepago}: <NumericFormat displayType="text" value={item.total_sum} prefix={'$'} thousandSeparator={true} /></span><br /></div>
                                    )
                                })}
                                <span style={{fontWeight: 500, fontSize: 17}}>Total: <NumericFormat displayType="text" value={this.props.mediosdepagoTot} prefix={'$'} thousandSeparator={true} /></span><br />
                              </div>
                            </div>



                            <div style={{flex:1}}>
                              <div style={{backgroundColor: '#fec8c8', padding: 5}}>
                                <span><b>Gastos:</b></span>
                              </div>
                              <div style={{padding: 5}}>
                                <table style={{width: '100%'}}>
                                  <thead>
                                    <tr>
                                    <th style={{backgroundColor: '#fec8c8', padding: 5, flex: 1}}>Fechas</th>
                                    <th style={{backgroundColor: '#fec8c8', padding: 5, flex: 1}}>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    
                                    {this.props.gastos.map((item, index) =>{
                                      return(
                                        <tr key={index}>
                                          <td><span>{item.fecha}</span></td>
                                          <td><NumericFormat displayType="text" value={parseInt(item.sum_gastos)} prefix={'$'} thousandSeparator={true} /></td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                                <span style={{fontWeight: 500, fontSize: 17}}>Total: <NumericFormat displayType="text" value={this.props.totalGastos} prefix={'$'} thousandSeparator={true} /></span><br />
                              </div>
                            </div>
                        </div>

                        <div style={{paddingTop: 10, display: 'flex'}} >
                              <div style={{flex:1}}>
                                    <div style={{backgroundColor: '#93c1e8', padding: 5}}>
                                      <span style={{fontWeight: 500, fontSize: 20}}>Total Ingresos: <NumericFormat displayType="text" value={this.props.totales.totaltotales} prefix={'$'} thousandSeparator={true}/></span>
                                    </div>
                              </div>
                              <div style={{flex:1}}>
                                    <div style={{backgroundColor: '#fec8c8', padding: 5}}>
                                    <span style={{fontWeight: 500, fontSize: 20}}>Total Gastos: <NumericFormat displayType="text" value={this.props.totalGastos} prefix={'$'} thousandSeparator={true}/></span>
                                    </div>
                              </div>
                              <div style={{flex:1}}>
                                  <div style={{backgroundColor: '#cae7ff', padding: 5}}>
                                    <span style={{fontWeight: 500, fontSize: 20}}>Saldo Total: <NumericFormat displayType="text" value={this.props.totales.totaltotales - this.props.totalGastos} prefix={'$'} thousandSeparator={true}/></span>
                                  </div>
                              </div>
                        </div>
                        <div style={{paddingTop: 10, display: 'flex'}} >
                              <div style={{flex:1}}>
                                    <div style={{backgroundColor: '#93c1e8', padding: 5}}>
                                      <span style={{fontWeight: 500, fontSize: 20}}>Diezmo de Diezmos: <NumericFormat displayType="text" value={this.props.totales.totaltotales * 0.10} prefix={'$'} thousandSeparator={true}/></span>
                                    </div>
                              </div>
                              <div style={{flex:1}}>
                                    <div style={{backgroundColor: '#fff4ca', padding: 5}}>
                                    <span style={{fontWeight: 500, fontSize: 20}}>Administrativo (4%): <NumericFormat displayType="text" value={this.props.totales.totaltotales * 0.04} prefix={'$'} thousandSeparator={true}/></span>
                                    </div>
                              </div>
                              <div style={{flex:1}}>
                                  <div style={{backgroundColor: '#cae7ff', padding: 5}}>
                                    <span style={{fontWeight: 500, fontSize: 20}}>Total Informe: <NumericFormat displayType="text" value={(this.props.totales.totaltotales * 0.10) + (this.props.totales.totaltotales * 0.04)} prefix={'$'} thousandSeparator={true}/></span>
                                  </div>
                              </div>
                              
                        </div>

                        <div style={{paddingTop: 10, display: 'flex'}} >
                            <table style={{width: '100%'}}>
                                      <thead>
                                        <tr>
                                        <th style={{backgroundColor: '#fec8c8', padding: 5, flex: 1}}>Categoría</th>
                                        <th style={{backgroundColor: '#fec8c8', padding: 5, flex: 1}}>Total</th>
                                        <th style={{backgroundColor: '#fec8c8', padding: 5, flex: 1}}>%</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        
                                        {this.props.catGastos.map((item, index) =>{
                                          return(
                                            <tr key={index}>
                                              <td style={{paddingLeft: 3}}><span> {item.categoría}</span></td>
                                              <td><NumericFormat displayType="text" value={parseInt(item.total)} prefix={'$'} thousandSeparator={true} /></td>
                                              <td>
                                              <span>
    {this.props.totalGastos > 0 
      ? parseFloat(((item.total / this.props.totalGastos) * 100).toFixed(1)) 
      : 0}% 
  </span>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                              </table>
                        </div>


                            <div style={{flex:1}}>
                                  <div className="footerInforme">
                                    {this.props.nombreEditor === '' ?
                                      <span><b>Informe realizado por: {this.props.NombreUsuario}</b></span>
                                    :
                                    <div>
                                      <span><b>Informe realizado por: {this.props.nombreEditor}</b> - </span>
                                      <span><b>Editado por: {this.props.NombreUsuario}</b></span>
                                    </div>
                                    }
                                    
                                   
                                  </div>
                            </div>
                            <hr/>
                      </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
  }
}


class Imprimir extends React.Component {

  render() {
    const pageStyle = `{ size: 2.5in 4in}`;
    return (
      <div >
        <ComponentToPrintDomi
          ref={el => (this.componentRef = el)}
          data={this.props.data}
          ima={this.props.ima}
          fechahoy={this.props.fechahoy}
          fechaIni={this.props.fechaIni}
          fechaFin={this.props.fechaFin}
          ultimoID={this.props.ultimoID}
          informeSumafechas={this.props.informeSumafechas}
          totales={this.props.totales}
          informeSumaTiposOfrenda={this.props.informeSumaTiposOfrenda}
          mediosdepagoOK={this.props.mediosdepagoOK}
          mediosdepagoTot={this.props.mediosdepagoTot}
          gastos={this.props.gastos}
          totalGastos={this.props.totalGastos}
          NombreUsuario={this.props.NombreUsuario}
          nombreEditor={this.props.nombreEditor}
          catGastos={this.props.catGastos}
          />
          <center>
          <ReactToPrint pageStyle={pageStyle} 
            trigger={() => 
              <div >
                  <buttom
                          className="btn boton_estandar_blanco float-right"							
                    ><FontAwesomeIcon icon={faFilePdf} size="lg"/> Imprimir o Guardar PDF
                  </buttom>
              </div>}
            content={() => this.componentRef}
          />
        </center>
      </div>
    );
  }
}

export default Imprimir;