import React, {useState, useEffect} from 'react'
import VerInformes from './VerInformes'
import SubirInformes from './SubirInformes'
import EditInformes from './EditInformes'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'

const InformesAdmin = ({baseUrl, titulo, Iglesiasids, Roll, NombreUsuario, UrlGastos}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [informes, setInformes] = useTraeDatos(baseUrl, Iglesiasids, Roll)
	const [copiaDonaciones] = useTraeDatos(baseUrl, Iglesiasids, Roll)
	const [busqueda, setBusqueda] = useState('')
	const TitulosPagina = ['Ver Informes', 'Crear Informe', 'Editar Informe', 'Eliminar Informe', 'Informes']

	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaDonaciones.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase())){
				return item
			} 
		})
		setInformes(resultado)
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, baseUrl, informes, setInformes, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const ReiniciarPaginacion = () => {
		setInformes(copiaDonaciones)
		setBusqueda('')
	}

	return(
		<div>
			<Header 
				titulo={tituloPag} 
				funcion={funcion} 
				setFuncion={setFuncion} 
				Pagina={'Membresia'} 
				RecibeBusqueda={RecibeBusqueda} 
				busqueda={busqueda}
				resetBusq={ReiniciarPaginacion}
			/>
            {funcion === 'crear' ?
                <SubirInformes 
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={informes} 
					setData={setInformes}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}
					NombreUsuario={NombreUsuario}
					UrlGastos={UrlGastos}
					/>

			:null}
			{funcion === 'editar' ?
				<SubirInformes
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={informes} 
					setData={setInformes}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					registroSelect={registroSelect}
					funcion={funcion}
					NombreUsuario={NombreUsuario}
					UrlGastos={UrlGastos}
					/>
			:null}
			{funcion === 'ver' ?
				<VerInformes
					data={informes} 
					seleccionaRegistro={seleccionaRegistro}
					baseUrl={baseUrl}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default InformesAdmin