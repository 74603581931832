import React, { useState, useEffect } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'

const SubirEditCategorias = ({ baseUrl, baseUrCategorias, data, setData, setFuncion, registroSelect, TituloBtn, funcion, medios }) => {
	const [registro, setRegistro] = useState(funcion === 'editar' ? {...registroSelect, fecha: moment(registroSelect.fecha).format("YYYY-MM-DD")} : {fecha: moment().format("YYYY-MM-DD"), imagePrev: fotoVacia, mediodepago: 'Efectivo' })
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)
	const [categorias, setcategorias] = useTraeDatos(baseUrCategorias)
	//registro.fechatermino = moment(registroSelect.fechatermino, 'YYYY-MM-DD').toDate()


	const creaFormData = (registro, METHOD) => {


		console.log('SUBIR', registro)
		let { id, nombre, color} = registro
		//let {id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva} = registro

		let f = new FormData()
		if (METHOD === 'PUT') { f.append('id', id) }
		f.append('nombre', nombre)
		f.append('color', color)
		f.append('METHOD', METHOD)
		return f
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {
		//const fecha = moment(new Date()).format("YYYY-MM-DD");
		let { nombre, color} = registro
			let f = new FormData()

			if (nombre !== undefined && color !== undefined) {
				
				f.append('nombre', nombre)
				f.append('color', color)
				f.append('METHOD', 'POST')

				await axios.post(baseUrl, f)

				.then(response => {
					console.log('result', response.data)
					let resultFormat = { ...response.data}
					setData([resultFormat, ...data])
					setRegistro({ activo: false })
					errores('Limpio', setError)
					setFuncion('ver')

				}).catch(error => {
					console.log(error);
				})
	
			} else errores('CampoVacio', setError)

	

	}

	const ProcedimientoPut = async () => {

		let { id, nombre, color } = registro

		let f = creaFormData(registro, 'PUT')

		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.color = color
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {

		let { nombre, color } = registro

		if (nombre !== '' && color !== '') {
			ProcedimientoPut()

		} else errores('CampoVacio', setError)

	}

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-4'>
								<MDBInput
									label="Nombre Categoría" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>
							</div>
							<div className='col-md-4'>
								<MDBInput
									label="Código Color" name="color" required className={error.valor ? "form-control is-invalid" : null} outline
									value={registro.color}
									onChange={e => setRegistro({ ...registro, color: e.target.value })}
								/>
							</div>
							<div className='col-md-4'>
								<br></br>
								<div className='estados' style={{backgroundColor: registro.color}}>
									<b>{registro.nombre}</b>
								</div>
							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditCategorias