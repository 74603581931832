import React, { useState, useEffect } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'

const SubirEditGastos = ({ baseUrl, baseUrCategorias, data, setData, setFuncion, registroSelect, TituloBtn, funcion, medios, categorias }) => {
	const [registro, setRegistro] = useState(funcion === 'editar' ? {...registroSelect, fecha: moment(registroSelect.fecha).format("YYYY-MM-DD")} : {fecha: moment().format("YYYY-MM-DD"), imagePrev: fotoVacia, mediodepago: 'Efectivo' })
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)
	//registro.fechatermino = moment(registroSelect.fechatermino, 'YYYY-MM-DD').toDate()


	const creaFormData = (registro, METHOD, NombreIma) => {


		console.log('SUBIR', registro)
		let { id, nombre, fecha, valor, mediodepago, imagen, imagenNueva, nombreImaNueva, categoria} = registro
		//let {id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva} = registro

		let f = new FormData()
		if (METHOD === 'PUT') { f.append('id', id) }
		f.append('nombre', nombre)
		f.append('fecha', fecha)
		f.append('valor', valor)
		f.append('mediodepago', parseInt(traeIdNombre(medios, mediodepago)))
		f.append('categoria', parseInt(traeIdNombre(categorias, categoria)))
		f.append('METHOD', METHOD)

		if (METHOD === 'PUT') {
			f.append('imagen', imagenNueva !== undefined ? imagenNueva : imagen)
			f.append('nombre_imagen', nombreImaNueva)
			if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }
		} else if (METHOD === 'POST') {
			f.append('imagen', imagen)
			f.append('nombre_imagen', NombreIma)
		}
		return f
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {
		//const fecha = moment(new Date()).format("YYYY-MM-DD");
		let { nombre, fecha, valor, imagen, mediodepago, categoria } = registro
		console.log('registro', registro)

		console.log('id de medio', traeIdNombre(medios, mediodepago))
		console.log('categoria', traeIdNombre(categorias, categoria))


		if (imagen !== undefined){

			let formatosPermitidos = ['.png', '.jpeg', '.jpg']
			let formato = imagen.type.replace('image/', '.');
			if (imagen.size < 2000000 && formatosPermitidos.includes(formato) && nombre !== undefined && valor !== undefined && fecha !== undefined   ) {

				
				let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
				let RutaServ = 'images/'
				console.log('nombreImagenFormat: ' + fecha + NombreImagen + formato)

				let NombreIma = RutaServ + fecha + NombreImagen + formato
				let f = creaFormData(registro, 'POST', NombreIma)

				await axios.post(baseUrl, f)

					.then(response => {
						console.log('result', response.data)
						peticionTraeDatos(baseUrl)
						setRegistro({ activo: false })
						errores('Limpio', setError)
						setFuncion('ver')

					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)
		} else {

			let { nombre, valor, mediodepago, fecha} = registro

			let f = new FormData()
			f.append('nombre', nombre)
			f.append('valor', valor)
			f.append('fecha', fecha)
			f.append('mediodepago', parseInt(traeIdNombre(medios, mediodepago)))
			f.append('categoria', parseInt(traeIdNombre(categorias, categoria)))
			f.append('METHOD', 'POST')

			await axios.post(baseUrl, f)

			.then(response => {
				console.log('result', response.data)
				peticionTraeDatos(baseUrl)
				setRegistro({ activo: false })
				errores('Limpio', setError)
				setFuncion('ver')

			}).catch(error => {
				console.log(error);
			})
		}

	}


	const peticionTraeDatos = async(baseUrl)=>{

		await axios.get(baseUrl)
		.then (response => {
			setData(response.data)
		}).catch(error =>{
			console.log(error)
		})
	} 

	const ProcedimientoPut = async () => {

		let { id, nombre, fecha, valor, mediodepago, imagen, imagenNueva, nombreImaNueva } = registro

		let f = creaFormData(registro, 'PUT')
		if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }

		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				peticionTraeDatos(baseUrl)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {

		let { nombre, valor, fecha, imagenNueva } = registro

		if (nombre !== '' && valor !== '' && fecha !== '') {
			if (imagenNueva !== undefined) {
				//Cuando Hay una imagen nueva
				let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
				console.log('imagenNueva', imagenNueva)
				let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;
				//Pregunta si el formato y peso son correctos
				if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {
					ProcedimientoPut()
				} else { errores('ImagenIncorrecta', setError) }
			} else ProcedimientoPut()

		} else errores('CampoVacio', setError)

	}

	const handleValueChange = (values, name) => {
		const { value } = values;
		// Usa el nombre para actualizar el campo correspondiente en el estado
		setRegistro((prevState) => ({
		  ...prevState,
		  [name]: value // Guarda el valor sin formato
		}));
	  };

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-9'>

								<span>Fecha</span><br></br>
								<input
									className='form-control'
										style={{marginTop:10}}
										type="date"
										value={registro.fecha }
										onChange={e => setRegistro({ ...registro, fecha: e.target.value })}
								/>

								<MDBInput
									label="Detalle del gasto" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

								<NumericFormat
										label="Valor"
										name="valor"
										thousandSeparator={true}
										prefix={'$'} // Si deseas añadir un prefijo (opcional)
										value={registro.valor}
										onValueChange={(values) => handleValueChange(values, 'valor')}
										customInput={MDBInput} // Usa MDBInput como componente de entrada
										className={error.valor ? "form-control is-invalid" : null}
										required
										outline
									/>

								<select 
											className="browser-default custom-select" 
											name="mediodepago"
											value={registro.mediodepago}
											onChange={e => setRegistro({...registro, mediodepago: e.target.value})}
											>
												<option value="">Selecciona Medio de Pago</option>
												{medios.map(item =>{
													return <option key={item.nombre} value={item.nombre}>{item.nombre}</option>
												})}

                                </select><br></br><br></br>

								<select 
											className="browser-default custom-select" 
											name="categoria"
											value={registro.categoria}
											onChange={e => setRegistro({...registro, categoria: e.target.value})}
											>
												<option value="">Selecciona Categoría</option>
												{categorias.map(item =>{
													return <option key={item.nombre} value={item.nombre}>{item.nombre}</option>
												})}

                                </select><br></br><br></br>


								<div className="input-group">
									<div className="input-group-prepend">
									</div>
									<div className="custom-file">
										<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
											name="imagen"
											onChange={recibeImagen(registro, setRegistro, funcion)}
										/>
										<label className="custom-file-label">
											Seleccionar Recibo (Imagen)
										</label>
									</div>
								</div>

								


								
							</div>

							<div className="col-md-3">
								<div className="FotoPrev">
									{funcion === 'editar' ?
										<img alt="imagen" src={
											registro.imagePrev === registro.imagen ?
												baseUrl + "/" + registro.imagePrev
												: registro.imagePrev
										} />
										:
										<img src={registro.imagePrev} />
									}
								</div>
								
								{funcion === 'editar' ?
									<div className="col text-center">
										<button onClick={() => {window.open(baseUrl + "/" + registro.imagePrev, '_blank');}} className="btn boton_estandar">Ver Imagen </button>
									</div>

								:null}
							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditGastos