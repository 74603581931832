import React, {useState, useEffect} from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faEdit  } from '@fortawesome/free-solid-svg-icons'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import SelectIglesiaBasico from '../componentes/SelectIglesiaBasico'
import useTraeDatos from '../hooks/useTraeDatos';

const TipoDonacion = ({UrlTipoDonacion, titulo, iglesias, Roll, Iglesiasids}) => {

	const [data, setData] = useTraeDatos(UrlTipoDonacion, Iglesiasids, Roll)

	const [registro, setRegistro] = useState({})
	//almacena la categoria seleccionada
	const [registroSelect, setRegistroSelect] = useState({})
	//selecciona la función como eliminar, crear, editar
	const [funcion, setFuncion] = useState('ver')
	//Alerta del error para la validacion de formulario
	const [alerta, setAlerta] = useState(false)


	const BuscaIglesia = (id) =>{
        
		let result = ''
		iglesias.map(item =>{
			if(id == item.id)
			result = item.nombre
		})
		return result
        
    }

	//realiza petición que borra de la base de datos por medio de la ID
	const peticionDelete=async()=>{
	    let f = new FormData();
	    f.append("METHOD", "DELETE");
	    await axios.post(UrlTipoDonacion, f, {params: {id: registroSelect.id}})
	    .then(response=>{
	      setData(data.filter(categoria=>categoria.id!==registroSelect.id))
	      setFuncion('ver')
	    }).catch(error=>{
	      console.log(error)
	    })
	
    }

	//Selecciona categoria para editarla o borrarla 
	const SeleccionarCategoria = (objeto, funcion) => e => {
		setFuncion(funcion)
		setRegistroSelect({
			id: objeto.id,
			nombre: objeto.nombre,
			idiglesia: objeto.idiglesia
		})
		if(funcion === 'editar'){
			setFuncion('editar')
		}
	}

	//Consulta para editar campos
	const peticionPut=async()=>{
		if(registroSelect.nombre !== '' && registroSelect.idiglesia !== ''){
		    let f = new FormData()
		    f.append("nombre", registroSelect.nombre)
			f.append("idiglesia", registroSelect.idiglesia)
		    f.append("METHOD", "PUT")
		    await axios.post(UrlTipoDonacion, f, {params: {id: registroSelect.id}})
		    .then(response=>{
				console.log('res',response)
		      let dataNueva = data
		      dataNueva.map(item=>{
		        if(item.id === registroSelect.id){
		          item.nombre=registroSelect.nombre
				  item.idiglesia=registroSelect.idiglesia
		        }
		      })
		      setData(dataNueva)
		      setFuncion('ver')
		      setAlerta(false)
		    }).catch(error=>{
		      console.log(error);
		    })
		}else{setAlerta(true)}
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () =>{
		if(registro.nombre !== '' && registro.id !== ''){
			let f = new FormData()
			f.append('nombre', registro.nombre)
			f.append('idiglesia', registro.id)
			f.append('METHOD', 'POST')
			await axios.post(UrlTipoDonacion, f)

			.then(response=>{
		      setData(data.concat(response.data));
		      setRegistro({})
		      setAlerta('')
		      setFuncion('ver')
		    }).catch(error=>{
		      console.log(error);
		    })
		}else{setAlerta(true)}
	}

	//Trae todas las categorias de la api
	// useEffect(() => {
	// 	peticionTraeDatos()
	// },[])



	return(
		<div>
			<Header titulo={titulo} funcion={funcion} setFuncion={setFuncion} />
			<div className="row">
				<div className="col-md-12">

					{funcion === 'editar' ?
						<div>
						<h4>Actualizar Tipo de Donación</h4>
						<hr/>

						<MDBInput 
							label="Nombre Donación" 
							value={registroSelect && registroSelect.nombre}
							onChange={e => setRegistroSelect({...registroSelect, nombre: e.target.value})}
							outline 
						/>
						
						<select 
							className="browser-default custom-select" 
							value={registroSelect.idiglesia}
							onChange={e => setRegistroSelect({...registroSelect, idiglesia: e.target.value})}
							>
							<option value="">Selecciona Iglesia</option>
							{iglesias.map((item, index) => {
								return(
									<option 
										key={index}
										value={item.id}>
										{item.nombre}
									</option>
								)}
							)}
						</select><br></br><br></br>


						{alerta ? <p className="error">¡Llena todos los campos!</p>: <span></span>}


						<div className="col text-center">
								<button 
									onClick={peticionPut}
									className="btn boton_estandar"							
									>Actualizar Medida
								</button>
								<button 
									onClick={e=>setFuncion('ver')}
									className="btn boton_estandar"							
									>Cancelar
								</button>
						</div>
						</div>
					: null}

					{funcion === 'crear' ?

						<div>
						<h4>Subir Tipo de Donación</h4>
						<hr/>
	
						  <MDBInput 
							label="Nombre de Donacion" 
							value={registro.nombre}
							onChange={e => setRegistro({...registro, nombre: e.target.value})}
							name="nombre"
							required
							outline />

							<SelectIglesiaBasico 
								iglesiaSelect={registro}
								setIglesiaSelect={setRegistro}
								iglesias={iglesias}
								Roll={Roll}/><br></br><br></br>

						{alerta ? <p className="error">¡Llena todos los campos!</p>: <span></span>}

						<div className="col text-center">
								
							<button 
								onClick={peticionPost}
								className="btn boton_estandar"							
								>Subir Tipo de Donación
							</button>
							<button 
								onClick={e=>setFuncion('ver')}
								className="btn boton_estandar"							
								>Cancelar
							</button>
							
						</div>
						</div>
					:null}

					{funcion === 'eliminar' ?

                        <Eliminar 
                            nombre={'Iglesia'}
                            select={registroSelect}
                            setFuncion={setFuncion}
                            peticionDelete={peticionDelete}
                        />
					:null}
					{funcion === 'ver' ?

                        <div>
                            <MDBTable hover>
								<HeaderTabla array={['Id','Nombre','Iglesia','IdPadre']} />
								<MDBTableBody>

									{data.map((item, index) => {
										return(
											<tr key={index}>
											<td>{item.id}</td>
											<td>{item.nombre}</td>
											<td>{BuscaIglesia(item.idiglesia)}</td>
											<td>{item.idiglesia}</td>
											<td className="tabla_funciones">
												<BotonesFormulario SeleccionarCategoria={SeleccionarCategoria} item={item} acceso={'full'}/>
											</td>
											</tr>
										)
									})}

								</MDBTableBody>
                            </MDBTable>
                        </div>

					:null}
				</div>
			</div>
		</div>
	)
}

export default TipoDonacion