import {useEffect, useState} from 'react'
import axios from 'axios'

const PeticionTraeDatos = (Url, Iglesiasids, Roll) => {

    const [data, setData] = useState([])

    const peticionTraeDatosIds = async()=>{

		await axios.get(Url, {params: {ids: JSON.stringify(Iglesiasids)}})
		.then (response => {
			setData(response.data)
		}).catch(error =>{
			console.log(error)
		})
	} 

    const peticionTraeDatosAll = async()=>{
        await axios.get(Url)
        .then (response => {setData(response.data)})
		
	} 

	const peticionTraeDatos = () =>{

		if(Roll == 'superadmin' ){
			peticionTraeDatosAll()
		}else{
			peticionTraeDatosIds()
		}
	}

    useEffect(() =>{
        peticionTraeDatos()
    },[Url])
    return [data, setData]
}

export default PeticionTraeDatos