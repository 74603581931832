import React from 'react'
import { MDBTable, MDBTableBody} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'

const VerIglesias = ({data, urlIglesias, seleccionaRegistro, Roll}) => {

	return(
				<MDBTable hover>
				  <HeaderTabla array={['','Imagen','Iglesia','Ciudad','Pastor','']} />
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(
					        <tr key={index} className="tabla_productos">
							  <td><div className={item.activo ? "bola_estado_activo" : "bola_estado_desact"}></div></td>
					          <td><div className="ImgProducto"><img src={`${urlIglesias}${item.imagen}`}/></div></td>
					          <td>{item.nombre}</td>
					          <td>{item.ciudad}</td>
							  <td>{item.pastor}</td>
                              <td className="tabla_funciones">
							  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={Roll}/>
					          </td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerIglesias