import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const BusquedaFechas = ({startDate, setStartDate, finalFecha, setFinalFecha, left}) => {

	const [indexPag, setIndexPag] = useState();
    let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
    let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

	return(
            <div style={{display: 'flex'}}>
                <label htmlFor="start">Inicial:</label>
                <input 
                    className='btn boton_estandar'
                    type="date" 
                    value={inicioFechaFormat}
                    onChange={e => setStartDate(e.target.value)}
                />
                <label htmlFor="start">Final:</label>
                <input 
                    className='btn boton_estandar'
                    type="date" 
                    value={finalFechaFormat}
                    onChange={e => setFinalFecha(e.target.value)}
                />
            </div>
	)
}



export default BusquedaFechas