import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import ima from '../../paginas/img/oasis.png'
import { NumericFormat } from 'react-number-format'
import BusquedaFechas from '../componentes/BusquedaFechas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
import Imprimir from './Imprimir'
import Cookies from 'universal-cookie'

const SubirInformes = ({ baseUrl, data, setData, setFuncion, registroSelect, TituloBtn, funcion, NombreUsuario, UrlGastos }) => {
	
	const [registro, setRegistro] = useState(funcion === 'editar' ? registroSelect : { activo: true, ids_tipoingresos: [], imagePrev: fotoVacia })
	const [error, setError] = useState({ activo: false })
	const [informeSumafechas, setInformeSumafechas] = useState(funcion === 'editar' ? JSON.parse(registroSelect.sumafechas) : [])
	const [informeSumaTiposOfrenda, setInformeSumaTiposOfrenda] = useState([])
	const [totales, setTotales] = useState(funcion === 'editar' ? JSON.parse(registroSelect.sumadonaciones) : {})
	const [fechahoy] = useState(funcion === 'editar' ? moment(registroSelect.fecha) : new Date());
	const [ultimoID, setUltimoId] = useState(funcion === 'editar' ? registroSelect.id : 0);
	const [gastos, setGastos] = useState([])
	const [totalGastos, setTotalGastos] = useState(0)
	const [mediosdepagoTot, setMediosdepagoTot] = useState(0)
	const [mediosdepagoOK, setMediosdepagoOK] = useState([])
	const [catGastos, setCatGastos] = useState([])
	
	const [nombreEditor, setNombreEditor] = useState(funcion === 'editar' ? registroSelect.nombre_usuario : '')

	const [startDate, setStartDate] = useState(funcion === 'editar' ? moment(registroSelect.fecha_inicio) : new Date());
	const [finalFecha, setFinalFecha] = useState(funcion === 'editar' ? moment(registroSelect.fecha_fin) : new Date());
	const cookies = new Cookies()

	//Consulta para subir o crear los datos 

	const sumaGastos = async(array)=>{
		let sumatoria = 0
		array.map(item =>{
			sumatoria = parseInt(item.sum_gastos) + sumatoria
		})
		setTotalGastos(sumatoria)
	}

	const peticionTraeSumaFechas= async(User)=>{
		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(baseUrl, {params: {tipo: 'suma_fechas', tipofecha: 'simple', date: inicioFechaFormat}})
		.then (response => {
			setInformeSumafechas(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeDatosAvanzaSumaFechas = async () => {
		//setLoading(false)
		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(baseUrl, { params: { tipo: 'suma_fechas' , tipofecha: 'avanzada', date: inicioFechaFormat, date2: finalFechaFormat } })
			.then(response => {
				let respuesta = response.data
				setInformeSumafechas(respuesta)
			})
			.catch(error => {console.log(error)})
	}

	const peticionTraeSumaTiposOfrenda= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(`${baseUrl}/ofrendas.php`, {params: {tipo: 'ofrendas', tipofecha: 'simple', date: inicioFechaFormat}})
		.then (response => {
			setInformeSumaTiposOfrenda(response.data)
			OrganizarTotales(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeSumaTiposOfrendaAvanzada= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(`${baseUrl}/ofrendas.php`, {params: {tipo: 'ofrendas', tipofecha: 'avanzada', date: inicioFechaFormat , date2: finalFechaFormat }})
		.then (response => {
			setInformeSumaTiposOfrenda(response.data)
			OrganizarTotales(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeGastosSimple= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(`${UrlGastos}/gastos_fechas.php`, {params: {tipo: 'simple', date: inicioFechaFormat}})
		.then (response => {
			setGastos(response.data)
			sumaGastos(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeGastosAvanzado= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(`${UrlGastos}/gastos_fechas.php`, {params: {tipo: 'avanzada', date: inicioFechaFormat, date2: finalFechaFormat}})
		.then (response => {
			setGastos(response.data)
			sumaGastos(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionObtenUltimoId= async()=>{
		await axios.get(`${baseUrl}/obtieneid.php`)
		.then (response => {
			setUltimoId(parseInt(response.data[0].ultimo_id) + 1)
		}).catch(error =>{console.log(error)})
	} 

	const OrganizarTotales = (array) => {
		let totaltotales = 0
		let totaldiezmos = 0
		let totalofrendas = 0
		let ofrendaseps = 0

		array.map(item =>{
			if(item.tipo_donacion === 'Total General'){
				totaltotales= item.total
				totaldiezmos = item.suma_diezmo
				totalofrendas = item.suma_ofrenda
			}else{
				ofrendaseps = parseFloat(item.suma_ofrenda) + ofrendaseps
			}
		})

		setTotales({totaltotales, totaldiezmos, totalofrendas, ofrendaseps})
	}

	const peticionTraeMediosSimple= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(`${baseUrl}/medios.php`, {params: {tipo: 'simple', date: inicioFechaFormat}})
		.then (response => {
			setMediosdepagoOK(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeCatGastosSimple= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		await axios.get(`${baseUrl}/catgastos.php`, {params: {tipo: 'simple', date: inicioFechaFormat}})
		.then (response => {
			setCatGastos(response.data)
			console.log('categorias', response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeMediosAvanzado= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(`${baseUrl}/medios.php`, {params: {tipo: 'avanzada', date: inicioFechaFormat, date2: finalFechaFormat}})
		.then (response => {
			GroupedSums(response.data)
		}).catch(error =>{console.log(error)})
	} 

	const peticionTraeCatGastosAvanzado= async()=>{

		let inicioFechaFormat = moment(startDate).format("YYYY-MM-DD")
		let finalFechaFormat = moment(finalFecha).format("YYYY-MM-DD")

		await axios.get(`${baseUrl}/catgastos.php`, {params: {tipo: 'avanzada', date: inicioFechaFormat, date2: finalFechaFormat}})
		.then (response => {
			setCatGastos(response.data)
			console.log('categorias', response.data)
		}).catch(error =>{console.log(error)})
	} 

	const ProcedimientoPut = async () => {

		let {id, nombre, cedula, telefono } = registro

		let f = creaFormData(registro, 'PUT')
		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.fecha = registroSelect.fecha
						item.fecha_inicio = startDate
						item.fecha_fin = finalFecha
						item.total = totales.totaltotales
						item.diezmo = totales.totaldiezmos
						item.ofrenda = totales.totalofrendas
						item.nombre_usuario = NombreUsuario
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {
			ProcedimientoPut()
	}

	const GroupedSums = (data) => {
		// Crear un objeto para almacenar las sumas por mediodepago
		const mediodepagoSums = {};
	  
		// Calcular las sumas por mediodepago
		data.forEach(item => {
		  const { mediodepago, total_sum } = item;
		  if (!mediodepagoSums[mediodepago]) {
			mediodepagoSums[mediodepago] = 0;
		  }
		  mediodepagoSums[mediodepago] += parseInt(total_sum, 10);
		});
	  
		// Crear un array con los resultados agrupados y sumados
		const resultArray = Object.keys(mediodepagoSums).map(mediodepago => ({
		  mediodepago,
		  total_sum: mediodepagoSums[mediodepago]
		}));

		let resultSumado = 0
		resultArray.map(item =>{
			resultSumado = item.total_sum + resultSumado
		})

		setMediosdepagoTot(resultSumado)
		setMediosdepagoOK(resultArray)
	}

	const creaFormData = (registro, METHOD) => {

		let { id } = registro
		const IdUsuario = cookies.get('id')
		console.log('IdUsuario',IdUsuario)

		let f = new FormData()
		f.append('id', ultimoID)
		f.append('total', totales.totaltotales)
		f.append('diezmo', totales.totaldiezmos)
		f.append('ofrenda', totales.totalofrendas)
		f.append('sumafechas', JSON.stringify(informeSumafechas))
		f.append('sumadonaciones', JSON.stringify(totales))
		f.append('gastos', JSON.stringify(gastos))
		f.append('usuario', IdUsuario)
		f.append('fecha_inicio', moment(startDate).format('YYYY-MM-DD'))
		f.append('fecha_fin', moment(finalFecha).format('YYYY-MM-DD'))
		f.append('METHOD', METHOD)

		return f
	}

	const peticionPost = async () => {


			if (totales.totaltotales > 0) {

				let f = creaFormData(registro, 'POST')
				await axios.post(baseUrl, f)

					.then(response => {
						console.log('result', response.data)
						let resultFormat = { ...response.data }
						setData([resultFormat, ...data])
						setRegistro({ activo: false })
						errores('Limpio', setError)
						setFuncion('ver')

					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)

	}


	useEffect(()=> {
		if(funcion !== 'editar'){
			peticionTraeSumaTiposOfrenda()
			peticionTraeSumaFechas()
			peticionObtenUltimoId()
			peticionTraeGastosSimple()
			peticionTraeMediosSimple()
			peticionTraeCatGastosSimple()
		} 
		
	},[])

	useEffect(() => {
		peticionTraeDatosAvanzaSumaFechas()
		peticionTraeSumaTiposOfrendaAvanzada()
		peticionTraeGastosAvanzado()
		peticionTraeMediosAvanzado()
		peticionTraeCatGastosAvanzado()
	}, [startDate, finalFecha])


	return (
		<div>
			<React.Fragment>
				<BusquedaFechas
					startDate={startDate}
					setStartDate={setStartDate}
					finalFecha={finalFecha}
					setFinalFecha={setFinalFecha}
				/>
			</React.Fragment>
			<div className="row">
				<div className="col-md-12" >
					<div>
						<Imprimir 
				          		data={totales}	
								  ima={ima}
								  fechahoy={fechahoy}
								  fechaIni={startDate}
								  fechaFin={finalFecha}
								  ultimoID={ultimoID}	
								  informeSumafechas={informeSumafechas}
								  totales={totales}
								  informeSumaTiposOfrenda={informeSumaTiposOfrenda}
								  mediosdepagoOK={mediosdepagoOK}
								  mediosdepagoTot={mediosdepagoTot}
								  gastos={gastos}
								  totalGastos={totalGastos}
								  NombreUsuario={NombreUsuario}
								  nombreEditor={nombreEditor}
								  catGastos={catGastos}
				          	/>

						<div className="row text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirInformes