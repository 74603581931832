import React, {useState, useEffect} from 'react'
// import { MDBInput } from 'mdbreact';
// import axios from 'axios'
// import { ResponsiveLine } from 'nivo'
// import Graficos from '../componentesAdmin/Graficos.js'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCalendarAlt, faCashRegister, faClipboardList, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
// import NumberFormat from 'react-number-format'
// import moment from 'moment'
// import TableroInforme from '../componentesAdmin/TableroInforme'  

const Escritorio = ({Titulo}) => {

	return(
		<div>
			<div>
				<br/>
				<h2>{Titulo}:</h2>	
				<hr/>
			</div>
		</div>
	)
}

export default Escritorio

