import React, {useState, useEffect} from 'react'
import VerMembresia from './VerMembresia'
import SubirEditMembresia from './SubirEditMembresia'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'

const MembresiaAdmin = ({baseUrl, titulo, Iglesiasids, Roll}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [membresias, setMembresias] = useTraeDatos(baseUrl, Iglesiasids, Roll)
	const [copiaDonaciones] = useTraeDatos(baseUrl, Iglesiasids, Roll)
	const [busqueda, setBusqueda] = useState('')
	const TitulosPagina = ['Ver membresias', 'Crear membresias', 'Editar membresias', 'Eliminar membresias', 'membresias']

	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaDonaciones.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase())){
				return item
			} 
		})
		setMembresias(resultado)
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, baseUrl, membresias, setMembresias, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const ReiniciarPaginacion = () => {
		setMembresias(copiaDonaciones)
		setBusqueda('')
	}

	return(
		<div>
			<Header 
				titulo={tituloPag} 
				funcion={funcion} 
				setFuncion={setFuncion} 
				Pagina={'Membresia'} 
				RecibeBusqueda={RecibeBusqueda} 
				busqueda={busqueda}
				resetBusq={ReiniciarPaginacion}
			/>
            {funcion === 'crear' ?
                <SubirEditMembresia
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={membresias} 
					setData={setMembresias}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}/>

			:null}
			{funcion === 'editar' ?
				<SubirEditMembresia
					baseUrl={baseUrl} 
					setFuncion={setFuncion}
					data={membresias} 
					setData={setMembresias}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					registroSelect={registroSelect}
					funcion={funcion}/>
			:null}
			{funcion === 'ver' ?
				<VerMembresia
					data={membresias} 
					seleccionaRegistro={seleccionaRegistro}
					baseUrl={baseUrl}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default MembresiaAdmin