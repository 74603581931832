import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './paginas/Login.js';
import Admin from './paginas/Admin.js'
// import Pos from './paginas/Pos.js'
import Error404 from './paginas/Error404.js';

const App = (_config) => {

	return(
      <BrowserRouter>
          <Routes>
            <Route exact path="/admin" element={<Admin />}></Route>
            <Route path="/" element={<Login />} />
          </Routes>
      </BrowserRouter>

      // <Routes>
      //   {/* <Route exact path="/" component={() => <Login _config={_config} />} />
      //   <Route exact path="/admin" component={() => <Admin _config={_config} />} />
      //   <Route exact path="/pos" component={() => <Pos _config={_config} />} /> 
      //   <Route path='/welcome' element={<Home/>} />*/}
      //   <Route component={<Error404/>} />
      // </Routes>

)}


export default App