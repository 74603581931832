import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'
import Tabla from '../componentes/Tabla'

const VerDonaciones = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {

	const columns = [
		{
			title: "Id", 
			field: "id", width: "5%", cellStyle: {padding: 15}, headerStyle: {padding: 10},
		},
		{
			title: "Nombre", 
			field: "nombre", width: "25%", cellStyle: {padding: 4}, headerStyle: {padding: 5},
		},
		{
			title: "Cédula", 
			field: "cedula", width: "25%", cellStyle: {padding: 4}, headerStyle: {padding: 5},
		},
		{
			title: "Teléfono", 
			field: "telefono", width: "25%", cellStyle: {padding: 4}, headerStyle: {padding: 5},
		},
		{
			render: (item) =><BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />,
			width: "20%", cellStyle: {padding: 0}, headerStyle: {padding: 10},
		},

	];
	const actions = [
	]

	return (
		<Tabla columns={columns} data={data} actions={actions} title='Listado de Miembros' />
	)
}

export default VerDonaciones