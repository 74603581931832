import React, {useState} from 'react'
import moment from 'moment'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import Tabla from '../componentes/Tabla'
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format'

const VerGastos = ({ data, seleccionaRegistro, baseUrl }) => {

	const [dateFilter, setDateFilter] = useState('');

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

	const columns = [
		{
			title: "Id", 
			field: "id", width: "5%", cellStyle: {padding: 15}, headerStyle: {padding: 10},
		},
		{
			title: 'Img',
			render: (item) => item.imagen !== '' ? <div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`} /></div>
			:<div className="ImgProductoSin"></div>,
			field: "", width: "1%", cellStyle: {padding: 4}, headerStyle: {padding: 5},

		},
		{
			title: "Fecha", 
			field: "fechaformat", 
		},
		{
			title: "Nombre", 
			field: "nombre", width: "25%", cellStyle: {padding: 4}, headerStyle: {padding: 5},
		},
		{
			title: "Valor", 
			render: (item) => <NumericFormat displayType="text" value={parseInt(item.valor)} prefix={'$'} thousandSeparator={true} />,
			field: "", 
		},
		{
			title: "Medio",
			field: "mediodepago",
		},
		{
			title: "Categoría",
			render: (item) =><span className='estados' style={{backgroundColor: item.colorcategoria}}>{item.categoria}</span>,
			field: "categoria", width: "20%", cellStyle: {padding: 4}, headerStyle: {padding: 5},
		},
		{
			render: (item) =><BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />,
			width: "20%", cellStyle: {padding: 0}, headerStyle: {padding: 10},
		},

	];
	const actions = [
	]

	return (
		<>
			<Tabla columns={columns} data={data} actions={actions} title='Listado de Gastos' />

		</>
		
	)
}

export default VerGastos