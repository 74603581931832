import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const Header = ({titulo, funcion, setFuncion, Roll, Pagina}) => {

	return(

        <div className="row">
            <div className="col-md-6">
                <br/>
                <h2>{titulo}:</h2>	
            </div>
            <div className="col-md-6">
                <div className="filtroPedidos">
                {funcion === 'ver' ?
                    <div>
                        {Pagina == 'iglesias' ?
                            <div>   
                                {Roll == 'superadmin' ? 
                                    <button 
                                        onClick={e => setFuncion('crear')}
                                        className="btn boton_estandar btn-lg derecha">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                :null}
                            </div>
                        :
                            <button 
                                onClick={e => setFuncion('crear')}
                                className="btn boton_estandar btn-lg derecha">
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        }
                    </div>
                :null}
                {funcion === 'crear' || funcion === 'editar' ?
                    <button 
                        onClick={e => setFuncion('ver')}
                        className="btn boton_estandar btn-lg derecha">
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                :null}
                
                </div>
            </div>
        </div>
	)
}



export default Header